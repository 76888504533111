<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :rules="rules">
        <a-form-item label="字典类别" name="piece">
          <a-select :labelInValue="!editFlag" v-model:value="form.category" placeholder="请选择" :disabled="editFlag">
            <a-select-option value="1">章节</a-select-option>
            <a-select-option value="2">块</a-select-option>
            <a-select-option value="3">一级</a-select-option>
            <a-select-option value="4">二级</a-select-option>
            <a-select-option value="5">三级</a-select-option>
            <a-select-option value="6">疾病类型</a-select-option>
            <a-select-option value="7">核保类别</a-select-option>
            <a-select-option value="8">就诊方式</a-select-option>
            <a-select-option value="9">险种类别</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="类别编码" name="categoryCode">
          <a-input v-model:value="form.categoryCode" :disabled="editFlag"/>
        </a-form-item>
        <a-form-item label="类别名称" name="categoryName">
          <a-input v-model:value="form.categoryName"/>
        </a-form-item>
        <a-form-item label="类别说明" name="categoryExplain">
          <a-input v-model:value="form.categoryExplain"/>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'DictionaryModel',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {},
      editFlag: false,
      rules: {
        categoryName: [
          {required: true, message: '请输入类别名称！', trigger: 'blur'},
        ],
        categoryCode: [
          {required: true, message: '请输入类别编码！', trigger: 'blur'},
        ]
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_dictionary");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add() {
      this.visible = true;
      this.title = '新增字典';
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑字典';
      this.form = record;
      this.form.category = record.dictionaryCategoryCode;
      this.editFlag = true;
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    save() {
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        let param = that.formData();
        if (!this.editFlag) {
          param.dictionaryCategoryCode = param.category.key;
          param.dictionaryCategory = param.category.label;
        }
        param.editFlag = this.editFlag;
        api.saveDictionary(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
