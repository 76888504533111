<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose" :width="width" :bodyStyle="bodyStyle">
    <a-card title="基本信息">
      <a-form layout="inline" :model="form">
        <a-form-item label="姓名" name="nameCode">
          <a-input v-model:value="form.nameCode" readOnly/>
        </a-form-item>
        <a-form-item label="身份证" name="userCode">
          <a-input v-model:value="form.userCode" readOnly/>
        </a-form-item>
      </a-form>
    </a-card>

    <a-card title="就医信息" style="margin-top: 20px;">
      <a-form layout="inline" :model="form">
        <a-form-item label="医院名称" name="hospitalName">
          <a-input v-model:value="form.hospitalName" readOnly/>
        </a-form-item>
        <a-form-item label="就诊科室" name="department">
          <a-input v-model:value="form.department" readOnly/>
        </a-form-item>
        <a-form-item label="就诊类型" name="visitTypeName">
          <a-input v-model:value="form.visitTypeName" readOnly/>
        </a-form-item>
        <a-form-item label="就诊时间" name="visitDateStr">
          <a-input v-model:value="form.visitDateStr" readOnly/>
        </a-form-item>
        <a-form-item label="入院时间" name="hospitalInTimeStr">
          <a-input v-model:value="form.hospitalInTimeStr" readOnly/>
        </a-form-item>
        <a-form-item label="出院时间" name="hospitalOutTimeStr">
          <a-input v-model:value="form.hospitalOutTimeStr" readOnly/>
        </a-form-item>
      </a-form>
    </a-card>

    <a-card title="疾病列表" style="margin-top: 20px;overflow: auto;">
      <a-table
          bordered
          :columns="columns"
          :data-source="diseaseVOList"
          rowKey="medicalDiseaseId"
          :pagination="false"
          :scroll="{ x: 'max-content'}">

      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import api from "@/api";

const columns = [
  {
    title: '序号',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '排查标记',
    dataIndex: 'positiveFlagName',
    width: 120,
    align: 'center',
  },
  {
    title: 'ICD编码',
    dataIndex: 'icdCode',
    width: 150,
    align: 'center',
  },
  {
    title: 'ICD名称',
    dataIndex: 'icdName',
    width: 150,
    align: 'center',
  },
];

export default {
  name: 'RecordDetail',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      title: '就医详情',
      width: '90%',
      bodyStyle: {
        minHeight: '500px',
      },
      medicalRecordId: '',
      form: {},
      columns,
      diseaseVOList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_auxiliary_before_positive");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    show(medicalRecordId) {
      this.visible = true;
      this.medicalRecordId = medicalRecordId;
      this.loadData(medicalRecordId);
    },
    loadData(medicalRecordId) {
      let that = this;
      let parm = {medicalRecordId: medicalRecordId};
      api.getBeforePositiveMedicalRecordDetail(parm).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.form = res.data;
        that.diseaseVOList = res.data.diseaseVOList;
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
