<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800" :zIndex="1">
      <a-spin :spinning="spinning">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="机构名称" name="unitName">
            <a-input v-model:value="form.unitName"/>
          </a-form-item>
          <a-form-item label="机构编码" name="unitCode">
            <a-input v-model:value="form.unitCode"/>
          </a-form-item>
          <a-form-item label="机构简称" name="unitAlias">
            <a-input v-model:value="form.unitAlias"/>
          </a-form-item>
          <a-form-item label="行政归属地" name="address">
            <a-input v-model:value="form.address"/>
          </a-form-item>
          <a-form-item label="参保日期标识" name="insureDateFlag">
            <a-select v-model:value="form.insureDateFlag">
              <a-select-option v-for="item in insureDateFlagData" :key="item.code" :value="item.code">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Token密钥" name="appSecret">
            <a-input v-model:value="form.appSecret" disabled/>
          </a-form-item>
          <a-form-item label="数据加密方法" name="encryptMethod">
            <a-select v-model:value="form.encryptMethod">
              <a-select-option v-for="item in encryptMethodData" :key="item.code" :value="item.code">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="数据加密秘钥" name="encryptKey">
            <a-input v-model:value="form.encryptKey" disabled/>
          </a-form-item>
          <a-form-item label="是否控制接口" name="controlApiFlag">
            <a-select v-model:value="form.controlApiFlag">
              <a-select-option v-for="item in controlApiFlagData" :key="item.code" :value="item.code">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="接口超时毫秒值" name="controlApiTime">
            <a-input-number v-model:value="form.controlApiTime" :min="1" :max="10000" placeholder="取值范围[1-10000]"
                            style="width: 100%"/>
          </a-form-item>

          <a-form-item label="配置业务" name="unitId">
            <a-button type="primary" @click="toConfMenu()">点击配置业务</a-button>
          </a-form-item>

          <a-form-item label="接口配置" name="unitId">
            <a-button type="primary" @click="toConfHealthTran()">点击配置接口</a-button>
          </a-form-item>

        </a-form>
        <div class="form-bootom-button">
          <a-button type="button" @click="close">取消</a-button>
          <a-button type="primary" @click="saveUnitInfo" style="margin-left: 10px">确定</a-button>
        </div>

      </a-spin>
    </a-modal>

    <MenuPermission ref="menuPermission" @saveMenuPermission="saveMenu"/>
    <HealthTransaction ref="healthTransaction" @saveHealthTransaction="saveHealthTran"/>

  </div>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";
import MenuPermission from "./MenuPermission";
import HealthTransaction from "./HealthTransaction";

export default {
  name: 'UnitInfoModel',
  mixins: [CommonMixin],
  components: {
    MenuPermission,
    HealthTransaction,
  },
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {
        unitName: '',
        unitCode: '',
        unitAlias: '',
        address: '',
        insureDateFlag: null,
        appSecret: '',
        encryptMethod: null,
        encryptKey: '',
      },
      rules: {
        unitName: [
          {required: true, message: '请输入机构名称！', trigger: 'blur'},
        ],
        unitCode: [
          {required: true, message: '请输入机构编号！', trigger: 'blur'},
        ],
        insureDateFlag: [
          {required: true, message: '请选择参保日期标识！', trigger: 'blur'},
        ],
        encryptMethod: [
          {required: true, message: '请选择数据加密方法！', trigger: 'blur'},
        ],
        controlApiFlag: [
          {required: true, message: '请选择是否控制接口！', trigger: 'blur'},
        ],
      },
      meunInfoData: [],
      sysResourceIdList: [],
      healthTranData: [],
      healthTranIdList: [],
      insureDateFlagData: [{code: 0, text: "禁用"}, {code: 1, text: "启用"}],
      controlApiFlagData: [{code: 0, text: "否"}, {code: 1, text: "是"}],
      encryptMethodData: [{code: 1, text: "国寿SM4"}, {code: 2, text: "平安SM4"}]
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_insurance_org_conf");
    },
    close() {
      this.visible = false;
      this.$emit('saveCuccess');
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add(parentId) {
      let that = this;
      that.visible = true;
      that.title = '新增';
      that.form.parentId = parentId ? parentId : 0;
      that.initMenuAndHealthTran(null);
      api.getUuid().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.form.appSecret = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
      api.getUuid().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.form.encryptKey = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';
      this.form = record;

      this.initMenuAndHealthTran(this.form.unitId);
    },
    initMenuAndHealthTran(unitId) {
      api.queryMenu({"unitId": unitId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.meunInfoData = res.data;
        this.sysResourceIdList = res.data.checkedAllKeys;
      }).catch(() => {
        this.$message.error("初始化菜单选项失败");
      });

      api.queryHealthTransaction({"unitId": unitId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.healthTranData = res.data;
        this.healthTranIdList = res.data.checkedAllKeys;
      }).catch(() => {
        this.$message.error("初始化交易码配置失败");
      });
    },
    okCallback() {
      this.$emit('saveCuccess');
    },
    formData() {
      return this.form;
    },
    saveUnitInfo() {
      // 保存
      let that = this;

      // 控制接口则需输入接口超时毫秒值
      if (that.form.controlApiFlag === 1) {
        if (!that.form.controlApiTime) {
          that.$message.error("请输入接口超时毫秒值");
          return;
        }
      }

      let parm = that.formData();
      parm.sysResourceIdList = this.sysResourceIdList;
      parm.healthTranIdList = this.healthTranIdList;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        api.saveUnitInfo(parm).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },
    toConfMenu() {
      this.$refs.menuPermission.show(this.meunInfoData, null);
    },
    toConfHealthTran() {
      this.$refs.healthTransaction.show(true, this.healthTranData);
    },
    saveMenu(sysResourceIdList) {
      this.sysResourceIdList = sysResourceIdList;
    },
    saveHealthTran(healthTranIdList) {
      this.healthTranIdList = healthTranIdList;
    },
  }
}
</script>