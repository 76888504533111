<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-form ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="文件">
        <a-upload
            :action="action"
            :headers="uploadHeaders"
            :file-list="fileList"
            :data="param"
            :before-upload="beforeUpload"
            @change="handleFileChange"
        >
          <a-button>
            <template #icon>
              <UploadOutlined/>
            </template>
            点击上传
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <div class="form-bootom-button">
      <a-button type="button" @click="close">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'UploadModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      title: '上传凭证',
      form: {},
      // 上传文件
      action: window._CONFIG["apiBaseUrl"] + api.auxiliaryAfterPreciseUploadUserLicense,
      fileList: [],
      param: {resultId: ''}
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_auxiliary_after_precise");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    show(resultId) {
      this.visible = true;
      this.param.resultId = resultId;
    },
    okCallback() {
      this.$emit('ok');
    },
    handleFileChange(info) {
      if (info.file.status === "done") {
        this.$message.success("上传成功！");
        this.close();
        this.okCallback();
      } else if (info.file.status === "error") {
        let message = "上传失败！";
        if (info.file.response && info.file.response.message) {
          message = info.file.response.message;
        }
        this.$message.error(message);
      }
      this.fileList = info.fileList;
    },
  }
}
</script>
