<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" title="辅助调查区域" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-spin :spinning="spinning">
      <a-tabs default-active-key="1" style="overflow: auto;">
        <a-tab-pane key="1" tab="健康平台">
          <AuxiliaryAreaTree ref="healthTree"/>
        </a-tab-pane>
        <a-tab-pane key="2" tab="医保平台" force-render>
          <AuxiliaryAreaTree ref="medicalTree"/>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import AuxiliaryAreaTree from "./AuxiliaryAreaTree";
import api from "@/api";

export default {
  name: 'AuxiliaryArea',
  mixins: [CommonMixin],
  components: {AuxiliaryAreaTree},
  data() {
    return {
      visible: false,
      spinning: false,
      unitId: null,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_insurance_org_conf");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    show(unitId) {
      let that = this;
      that.visible = true;
      that.unitId = unitId;
      that.spinning = true;
      api.auxiliaryAreaData(unitId).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          that.$refs.healthTree.clear();
          that.$refs.medicalTree.clear();
          return;
        }
        that.$refs.healthTree.render(unitId, 1, res.data.healthTree);
        that.$refs.medicalTree.render(unitId, 2, res.data.medicalTree);
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
        that.$refs.healthTree.clear();
        that.$refs.medicalTree.clear();
      });
    },
  }
}
</script>
