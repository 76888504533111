<template>
  <div>
    <div class="operator-info">
      <img src="@/assets/default_avatar.jpg" class="operator-img"/>
      <div class="operator-detail">
        <div style="font-size: 20px;font-weight: bold;">{{ operator.realName }}</div>
        <div style="font-size: 14px;">机构：{{ unitInfo.unitName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexDefault',
  data() {
    return {
      operator: this.$store.state.user.operator,
      unitInfo: this.$store.state.user.unitInfo,
    };
  },
};
</script>

<style scoped>
.operator-info {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
}

.operator-img {
  width: 100px;
  height: 100px;
}

.operator-detail {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.operator-view {
  font-size: 14px;
  color: #1890ff;
  border: 1px solid #1890ff;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}
</style>