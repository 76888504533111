<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :width="modelWidth">
    <div v-for="(insurance, index) in insuranceList" v-bind:key="insurance.archivesInsuranceId"
         style="margin-bottom: 25px;">
      <h3 :style="{ marginBottom: '10px' }">保险信息-{{ index + 1 }}</h3>
      <a-descriptions bordered>
        <a-descriptions-item label="保单号" :span="1">{{ insurance.commercialBillNum }}</a-descriptions-item>
        <a-descriptions-item label="保单开始时间" :span="1">{{ insurance.beginDate }}</a-descriptions-item>
        <a-descriptions-item label="保单结束时间" :span="1">{{ insurance.endDate }}</a-descriptions-item>
        <a-descriptions-item label="险种代码" :span="1">{{ insurance.insuranceCode }}</a-descriptions-item>
        <a-descriptions-item label="险种名称" :span="2">{{ insurance.insuranceName }}</a-descriptions-item>
      </a-descriptions>
    </div>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'InsuranceModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      form: {},
      modelWidth: "80%",
      insuranceList: null,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claim_query");
    },
    close() {
      this.visible = false;
    },
    show(insuranceList) {
      this.visible = true;
      this.insuranceList = insuranceList;
    },
    formData() {
      return this.form;
    },
  }
}
</script>
