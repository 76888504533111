<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800" :zIndex="1">
      <a-spin :spinning="spinning">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="账号" name="operatorName">
            <a-input v-model:value="form.operatorName" :disabled="readonly || isEdit"/>
          </a-form-item>
          <a-form-item label="姓名" name="realName">
            <a-input v-model:value="form.realName" :disabled="readonly"/>
          </a-form-item>
          <a-form-item label="身份证" name="cardNo">
            <a-input v-model:value="form.cardNo" :disabled="readonly"/>
          </a-form-item>
          <a-form-item label="联系方式" name="mobilePhone">
            <a-input v-model:value="form.mobilePhone" :disabled="readonly"/>
          </a-form-item>
          <a-form-item label="工号" name="jobNo">
            <a-input v-model:value="form.jobNo" :disabled="readonly"/>
          </a-form-item>

          <a-form-item label="角色" name="roleList" v-show="isGeneralOpeartor == 1">
            <a-select mode="multiple" v-model:value="form.roleList" :allowClear="true" placeholder="请选择"
                      optionFilterProp="children">
              <a-select-option v-for="item in roleList" :key="item.roleId" :value="item.roleId">
                {{ item.roleName }}
              </a-select-option>
            </a-select>
          </a-form-item>

        </a-form>

        <div class="form-bootom-button" v-show="!readonly">
          <a-button type="button" @click="close">取消</a-button>
          <a-button type="primary" @click="saveOperatorInfo" style="margin-left: 10px">确定</a-button>
        </div>

      </a-spin>
    </a-modal>

  </div>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'OperatorModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      readonly: false,
      isEdit: false,
      title: '',
      form: {},
      rules: {
        operatorName: [
          {required: true, message: '请输入账号！', trigger: 'blur'},
        ],
        realName: [
          {required: true, message: '请输入姓名！', trigger: 'blur'},
        ],
      },
      unitId: null,
      roleList: [],
      containerId: 'comptainer_insurance_org_conf',
      isGeneralOpeartor: null,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById(this.containerId);
    },
    close() {
      this.visible = false;
      this.$emit('saveCuccess');
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add(unitId, containerId, isGeneralOpeartor) {
      this.visible = true;
      this.title = '新增';
      this.unitId = unitId;
      if (containerId != null) {
        this.containerId = containerId;
      }
      if (isGeneralOpeartor != null) {
        this.isGeneralOpeartor = isGeneralOpeartor;
        this.getOrgRoleList();
      }
    },
    edit(record, containerId, isGeneralOpeartor, title, readonly) {
      this.isEdit = true;
      this.visible = true;
      this.title = '编辑';
      if (title) {
        this.title = title;
      }
      this.form = record;
      if (record.roleIds) {
        this.form.roleList = record.roleIds.split(',').map(Number);
      } else {
        this.form.roleList = [];
      }
      if (containerId != null) {
        this.containerId = containerId;
      }
      if (isGeneralOpeartor != null) {
        this.isGeneralOpeartor = isGeneralOpeartor;
        this.getOrgRoleList();
      }
      if (readonly) {
        this.readonly = true;
      }
    },
    okCallback() {
      this.$emit('saveCuccess');
    },
    formData() {
      return this.form;
    },
    saveOperatorInfo() {
      // 保存
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        let param = that.formData();
        param.unitId = this.unitId;
        if (this.isGeneralOpeartor != null) {
          param.orgMgrFlag = 0;
        } else {
          param.orgMgrFlag = 1;
        }
        api.saveOperatorInfo(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },
    getOrgRoleList() {
      api.querySysRoleByUnitId().then(res => {
        this.loading = false;
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          this.$message.error("获取机构角色失败:" + res.message);
          return;
        }
        this.roleList = res.data;
      }).catch(() => {
        this.loading = false;
        this.$message.error("获取机构角色失败");
      });
    },

  }
}
</script>