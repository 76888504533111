<template>
  <div class="comptainer" id="comptainer_index" v-show="current=='index'">
    <Index/>
  </div>

  <div class="comptainer" id="comptainer_login_log" v-show="current=='login_log'">
    <LoginLog v-if="menuLoad('login_log')"/>
  </div>

  <div class="comptainer" id="comptainer_resource_manager" v-show="current=='resource_manager'">
    <ResourceManager v-if="menuLoad('resource_manager')"/>
  </div>

  <!-- 标准医院 -->
  <div class="comptainer" id="comptainer_standard_hospital" v-show="current=='standard_hospital'">
    <StandardHospital v-if="menuLoad('standard_hospital')"/>
  </div>

  <!-- 健康平台医院 -->
  <div class="comptainer" id="comptainer_health_platform_hospital" v-show="current=='health_platform_hospital'">
    <HealthPlatform v-if="menuLoad('health_platform_hospital')"/>
  </div>

  <!-- 医保平台医院 -->
  <div class="comptainer" id="comptainer_medical_platform_hospital" v-show="current=='medical_platform_hospital'">
    <MedicalPlatform v-if="menuLoad('medical_platform_hospital')"/>
  </div>

  <!-- 保险机构配置 -->
  <div class="comptainer" id="comptainer_insurance_org_conf" v-show="current=='insurance_org_conf'">
    <InsuranceOrgConf v-if="menuLoad('insurance_org_conf')"/>
  </div>

  <!-- 机构管理-角色管理-->
  <div class="comptainer" id="comptainer_insurance_org_manager" v-show="current=='org_role_manager'">
    <OrgRoleManager v-if="menuLoad('org_role_manager')"/>
  </div>

  <!-- 机构管理-操作员管理-->
  <div class="comptainer" id="comptainer_insurance_org_operator_manager" v-show="current=='org_operator_manager'">
    <OrgOperatorManager v-if="menuLoad('org_operator_manager')"/>
  </div>

  <!-- 用户凭证查询 -->
  <div class="comptainer" id="comptainer_query_user_license" v-show="current=='query_user_license'">
    <UserLicenseList v-if="menuLoad('query_user_license')"/>
  </div>

  <!-- 疾病类别配置 -->
  <div class="comptainer" id="comptainer_disease_category" v-show="current=='disease_category'">
    <DiseaseCategoryList v-if="menuLoad('disease_category')"/>
  </div>

  <!-- 核保类别配置 -->
  <div class="comptainer" id="comptainer_underwriting_category" v-show="current=='underwriting_category'">
    <UnderwritingCategoryList v-if="menuLoad('underwriting_category')"/>
  </div>

  <!-- 保障责任配置 -->
  <div class="comptainer" id="comptainer_responsibility" v-show="current=='responsibility'">
    <ResponsibilityList v-if="menuLoad('responsibility')"/>
  </div>

  <!-- 基础数据-ICD配置 -->
  <div class="comptainer" id="comptainer_icd_conf" v-show="current=='icd_conf'">
    <IcdList v-if="menuLoad('icd_conf')"/>
  </div>

  <!-- 基础数据-字典维护 -->
  <div class="comptainer" id="comptainer_dictionary" v-show="current=='dictionary'">
    <DictionaryList v-if="menuLoad('dictionary')"/>
  </div>

  <!-- 基础数据-就诊方式维护 -->
  <div class="comptainer" id="comptainer_treatment_method" v-show="current=='treatment_method'">
    <TreatmentDiseaseList v-if="menuLoad('treatment_method')"/>
  </div>

  <!-- 医保目录 -->
  <div class="comptainer" id="comptainer_medical_directory_list" v-show="current=='medical_directory_list'">
    <MedicalDirectoryList v-if="menuLoad('medical_directory_list')"/>
  </div>

  <!-- 理赔查询 -->
  <div class="comptainer" id="comptainer_claim_query" v-show="current=='claim_query'">
    <ClaimQueryList v-if="menuLoad('claim_query')"/>
  </div>

  <!-- 保前风险等级 -->
  <div class="comptainer" id="comptainer_auxiliary_before_risk" v-show="current=='auxiliary_before_risk'">
    <BeforeRiskQuery v-if="menuLoad('auxiliary_before_risk')"/>
  </div>

  <!-- 保前阳性率 -->
  <div class="comptainer" id="comptainer_auxiliary_before_positive" v-show="current=='auxiliary_before_positive'">
    <BeforePositiveQuery v-if="menuLoad('auxiliary_before_positive')"/>
  </div>

  <!-- 保前精准调查 -->
  <div class="comptainer" id="comptainer_auxiliary_before_precise" v-show="current=='auxiliary_before_precise'">
    <BeforePreciseQuery v-if="menuLoad('auxiliary_before_precise')"/>
  </div>

  <!-- 保后风险等级 -->
  <div class="comptainer" id="comptainer_auxiliary_after_risk" v-show="current=='auxiliary_after_risk'">
    <AfterRiskQuery v-if="menuLoad('auxiliary_after_risk')"/>
  </div>

  <!-- 保后阳性率 -->
  <div class="comptainer" id="comptainer_auxiliary_after_positive" v-show="current=='auxiliary_after_positive'">
    <AfterPositiveQuery v-if="menuLoad('auxiliary_after_positive')"/>
  </div>

  <!-- 保后精准调查 -->
  <div class="comptainer" id="comptainer_auxiliary_after_precise" v-show="current=='auxiliary_after_precise'">
    <AfterPreciseQuery v-if="menuLoad('auxiliary_after_precise')"/>
  </div>

  <!-- 理赔指引出险原因 -->
  <div class="comptainer" id="comptainer_claims_guide_accident_reason"
       v-show="current=='claims_guide_accident_reason'">
    <AccidentReasonList v-if="menuLoad('claims_guide_accident_reason')"/>
  </div>

  <!-- 理赔指引申请事项 -->
  <div class="comptainer" id="comptainer_claims_guide_apply_item"
       v-show="current=='claims_guide_apply_item'">
    <ApplyItemList v-if="menuLoad('claims_guide_apply_item')"/>
  </div>

  <!-- 理赔指引申请人身份 -->
  <div class="comptainer" id="comptainer_claims_guide_applicant_identity"
       v-show="current=='claims_guide_applicant_identity'">
    <ApplicantIdentityList v-if="menuLoad('claims_guide_applicant_identity')"/>
  </div>

  <!-- 理赔指引资料 -->
  <div class="comptainer" id="comptainer_claims_guide_material"
       v-show="current=='claims_guide_material'">
    <MaterialList v-if="menuLoad('claims_guide_material')"/>
  </div>

  <!-- 理赔指引资料配置 -->
  <div class="comptainer" id="comptainer_claims_guide_material_config"
       v-show="current=='claims_guide_material_config'">
    <MaterialConfig v-if="menuLoad('claims_guide_material_config')"/>
  </div>

</template>

<script>
import Index from "@/view/index/Index";
import LoginLog from "@/view/syslog/LoginLog";
import ResourceManager from "@/view/system/resource/ResourceManager";
import StandardHospital from "@/view/baseconf/hospital/StandardHospital";
import HealthPlatform from "@/view/baseconf/health/HealthPlatform";
import MedicalPlatform from "@/view/baseconf/medical/MedicalPlatform";
import InsuranceOrgConf from "@/view/orgconf/InsuranceOrgConf";
import OrgRoleManager from "@/view/orgmgr/OrgRoleManager";
import OrgOperatorManager from "@/view/orgmgr/OrgOperatorManager";
import UserLicenseList from "@/view/query/UserLicenseList";
import DiseaseCategoryList from "@/view/baseconf/icd/DiseaseCategoryList";
import UnderwritingCategoryList from "@/view/baseconf/icd/UnderwritingCategoryList";
import ResponsibilityList from "@/view/resp/ResponsibilityList";
import IcdList from "@/view/baseconf/icd/IcdList";
import DictionaryList from "@/view/baseconf/dict/DictionaryList";
import TreatmentDiseaseList from "@/view/baseconf/treat/TreatmentDiseaseList";
import MedicalDirectoryList from "@/view/baseconf/medidict/MedicalDirectoryList";
import ClaimQueryList from "@/view/claim/ClaimQueryList";
import BeforeRiskQuery from "@/view/auxiliary/before/risk/RiskQuery";
import BeforePositiveQuery from "@/view/auxiliary/before/positive/PositiveQuery";
import BeforePreciseQuery from "@/view/auxiliary/before/precise/PreciseQuery";
import AfterRiskQuery from "@/view/auxiliary/after/risk/RiskQuery";
import AfterPositiveQuery from "@/view/auxiliary/after/positive/PositiveQuery";
import AfterPreciseQuery from "@/view/auxiliary/after/precise/PreciseQuery";
import AccidentReasonList from "@/view/claim/guide/AccidentReasonList";
import ApplyItemList from "@/view/claim/guide/ApplyItemList";
import ApplicantIdentityList from "@/view/claim/guide/ApplicantIdentityList";
import MaterialList from "@/view/claim/guide/MaterialList";
import MaterialConfig from "@/view/claim/guide/MaterialConfig";

export default {
  name: 'LayoutContent',
  components: {
    Index,
    LoginLog,
    ResourceManager,
    StandardHospital,
    HealthPlatform,
    MedicalPlatform,
    InsuranceOrgConf,
    OrgRoleManager,
    OrgOperatorManager,
    UserLicenseList,
    DiseaseCategoryList,
    UnderwritingCategoryList,
    ResponsibilityList,
    IcdList,
    DictionaryList,
    TreatmentDiseaseList,
    MedicalDirectoryList,
    ClaimQueryList,
    BeforeRiskQuery,
    BeforePositiveQuery,
    BeforePreciseQuery,
    AfterRiskQuery,
    AfterPositiveQuery,
    AfterPreciseQuery,
    AccidentReasonList,
    ApplyItemList,
    ApplicantIdentityList,
    MaterialList,
    MaterialConfig,
  },
  data() {
    return {
      menu: [],
      current: 'index'
    };
  },
  methods: {
    changeMenu(id) {
      this.current = id;
      if (this.menu.indexOf(id) == -1) {
        this.menu.push(id);
      }
    },
    menuLoad(id) {
      if (this.menu.indexOf(id) > -1) {
        return true;
      }
      return false;
    },
    clearNavigation() {
      this.menu = [];
      this.current = 'index';
    }
  }
};
</script>
