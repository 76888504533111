<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="modelWidth">
      <a-spin :spinning="spinning">
        <h3>险种信息</h3>
        <a-form ref="form" :model="form" layout="inline" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
          <a-form-item label="险种代码" name="insureCode">
            <a-input v-model:value="form.insureCode"/>
          </a-form-item>
          <a-form-item label="险种名称" name="insureName">
            <a-input v-model:value="form.insureName"/>
          </a-form-item>
          <a-form-item label="险种简称" name="insureShortName">
            <a-input v-model:value="form.insureShortName"/>
          </a-form-item>
          <a-form-item label="险种类别" name="insureType">
            <a-select v-model:value="form.insureType" :allowClear="true" placeholder="请选择" style="width: 420px">
              <a-select-option v-for="item in insureTypeOptionList" :key="item.categoryCode">
                {{ item.categoryName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="免责期(天)" name="exemptionPeriod">
            <a-input-number v-model:value="form.exemptionPeriod" style="width: 420px;"/>
          </a-form-item>

          <h3 :style="{ marginTop: '25px' }">保障信息</h3>
          <a-form-item label="免赔额" name="deductible">
            <a-input-number v-model:value="form.deductible" prefix="￥" style="width: 420px;"/>
          </a-form-item>
          <a-form-item label="出险原因" name="accidentReasonList">
            <a-select mode="multiple" v-model:value="form.accidentReasonList" :allowClear="true" placeholder="请选择"
                      style="width: 420px">
              <a-select-option v-for="item in accidentReasonOption" :key="item.categoryCode">
                {{ item.categoryName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <br/>
          <a-form-item label="就诊方式" name="treatmentCheckList">
            <a-checkbox-group v-model:value="form.treatmentCheckList"
                              @change="selectTreat($event, form.treatmentCheckList)"
                              style="width: 750px">
              <a-checkbox v-for="obj in treatOptionList" :key="obj.categoryCode" :value="obj.categoryCode">
                {{ obj.categoryName }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-item>
        </a-form>
        <div class="form-bootom-button">
          <a-button type="button" @click="close">取消</a-button>
          <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
        </div>
      </a-spin>
      <div class="table-wrapper">
        <a-table
            bordered
            :columns="columns"
            :data-source="icdDiagnosisList"
            :rowKey="rowKey"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            size="small"
            :scroll="{ x: 'max-content'}">
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '章节',
    dataIndex: 'chapter',
    width: '2%',
    align: 'center',
  },
  {
    title: '块',
    dataIndex: 'piece',
    width: '8%',
    align: 'center',
  },
  {
    title: '一级',
    dataIndex: 'onelevel',
    width: '8%',
    align: 'center',
  },
  {
    title: '二级',
    dataIndex: 'twolevel',
    width: '8%',
    align: 'center',
  },
  {
    title: '三级',
    dataIndex: 'threelevel',
    width: '8%',
    align: 'center',
  },
  {
    title: 'ICD编码',
    dataIndex: 'icdCode',
    width: '4%',
    align: 'center',
  },
  {
    title: 'ICD名称',
    dataIndex: 'icdName',
    width: '9%',
    align: 'center',
  },
  {
    title: '疾病类别',
    dataIndex: 'diseaseProfileName',
    width: '3%',
    align: 'center',
  },
  {
    title: '出险原因',
    dataIndex: 'accidentReasonName',
    width: '3%',
    align: 'center',
  },
  {
    title: '核保类别',
    dataIndex: 'underwritingCategory',
    width: '3%',
    align: 'center',
    customRender: ({text}) => {
      if (text == 1) {
        return "一类";
      }
      if (text == 2) {
        return "二类";
      }
      if (text == 3) {
        return "三类";
      }
      if (text == 4) {
        return "四类";
      }
      if (text == 5) {
        return "五类";
      }
    }
  },
];

export default {
  name: 'ResponsibilityModel',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      modelWidth: "95%",
      form: {},
      loading: false,
      rules: {
        insureCode: [
          {required: true, message: '请输入险种代码！', trigger: 'blur'},
        ],
        insureName: [
          {required: true, message: '请输入险种名称！', trigger: 'blur'},
        ],
        insureType: [
          {required: true, message: '请输入险种类别！', trigger: 'blur'},
        ],
        deductible: [
          {required: true, message: '请输入免赔额！', trigger: 'blur'},
        ],
        accidentReasonList: [
          {required: true, message: '请选择出险原因！', trigger: 'blur'},
        ],
        treatmentCheckList: [
          {required: true, message: '请选择就诊方式！', trigger: 'blur'},
        ]
      },
      treatOptionList: [],
      labelCol: {style: {width: '120px'}},
      wrapperCol: {span: 105, style: {width: '420px'}},
      rowKey: "icdId",
      columns,
      icdDiagnosisList: [],
      record: {},
      insureTypeOptionList: [],
      accidentReasonOption: [{"categoryCode": 1, "categoryName": "疾病"}, {"categoryCode": 2, "categoryName": "意外"}],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_responsibility");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add() {
      let that = this;
      that.visible = true;
      that.title = '新增';
      api.queryBaseDictionary({"dictionaryCategoryCode": 8}).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.treatOptionList = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
      api.queryBaseDictionary({"dictionaryCategoryCode": 9}).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.insureTypeOptionList = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    edit(record) {
      let that = this;
      that.visible = true;
      that.title = '编辑';
      that.record = record;
      that.loadData(that.record);
    },
    loadData(record) {
      let that = this;
      that.form = record;
      that.record = record;
      record.pageParm = {};
      record.pageParm.pageNum = that.pagination.current;
      record.pageParm.pageSize = that.pagination.pageSize;
      api.queryResponsibilityInfo(record).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.treatOptionList = res.data.treatOptionList;
        that.record.treatmentCheckList = res.data.treatmentCheckList;
        that.icdDiagnosisList = res.data.pageInfo.list;
        that.pagination.total = res.data.pageInfo.total;
        that.insureTypeOptionList = res.data.insureTypeOptionList;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    handleTableChange(pagination) {
      let that = this;
      this.pagination = pagination;
      this.record.pageParm.pageNum = pagination.current;
      this.record.pageParm.pageSize = pagination.pageSize;
      api.getIcdListBytreatmentCode(this.record).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.icdDiagnosisList = res.data.pageInfo.list;
        that.pagination.total = res.data.pageInfo.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    selectTreat(e, treatmentCheckList) {
      if (treatmentCheckList) {
        let that = this;
        that.record.treatmentCheckList = treatmentCheckList;
        that.record.pageParm = {};
        that.record.pageParm.pageNum = that.pagination.current;
        that.record.pageParm.pageSize = that.pagination.pageSize;
        api.getIcdListBytreatmentCode(that.record).then(res => {
          that.loading = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.icdDiagnosisList = res.data.pageInfo.list;
          that.pagination.total = res.data.pageInfo.total;
        }).catch(() => {
          that.loading = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      } else {
        this.record.treatmentCheckList = [];
      }
    },
    save() {
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        let param = that.formData();
        console.log(param);
        api.saveResponsibilityInfo(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
