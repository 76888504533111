<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="姓名" name="realName">
        <a-input v-model:value="form.realName" placeholder=""/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">
      </a-table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

const columns = [
  {
    title: '用户名',
    dataIndex: 'operatorName',
    width: 150,
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: 150,
    align: 'center',
  },
  {
    title: '机构',
    dataIndex: 'unitName',
    width: 150,
    align: 'center',
  },
  {
    title: '角色',
    dataIndex: 'roleName',
    width: 150,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '登录IP',
    dataIndex: 'loginIp',
    width: 150,
    align: 'center',
  },
  {
    title: '登录时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center',
  },
];

export default {
  name: 'LoginLog',
  mixins: [CommonMixin],
  created() {
    this.loadData();
  },
  data() {
    return {
      form: {
        realName: ''
      },
      rowKey: 'id',
      columns,
      dataSource: [],
      loading: false,
    }
  },
  methods: {
    formData() {
      return this.form;
    },
    loadData() {
      let that = this;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.loginLogData(that.formData()).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
  }
}
</script>
