<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="险种代码" name="insureCode">
        <a-input v-model:value="form.insureCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="险种名称" name="insureName">
        <a-input v-model:value="form.insureName" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="toAdd">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEdit(record)">编辑</a>
          </template>
        </template>

      </a-table>
    </div>

    <ResponsibilityModel ref="responsibilityModel" @ok="responsibilityModelOk"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import ResponsibilityModel from "./ResponsibilityModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '险种代码',
    dataIndex: 'insureCode',
    width: '8%',
    align: 'center',
  },
  {
    title: '险种名称',
    dataIndex: 'insureName',
    width: '10%',
    align: 'center',
  },
  {
    title: '险种类别',
    dataIndex: 'insureTypeName',
    width: '4%',
    align: 'center',
  },
  {
    title: '险种简称',
    dataIndex: 'insureShortName',
    width: '5%',
    align: 'center',
  },
  {
    title: '就诊方式',
    dataIndex: 'treatmentMethodName',
    width: '8%',
    align: 'center',
  },
  {
    title: '出险原因',
    dataIndex: 'accidentReasonName',
    width: '4%',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '3%',
    align: 'center',
  },
];

export default {
  name: 'DictionaryList',
  mixins: [CommonMixin],
  components: {
    ResponsibilityModel
  },
  data() {
    return {
      form: {},
      rowKey: "responsibilityId",
      columns,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageParm = {};
      that.form.pageParm.pageNum = that.pagination.current;
      that.form.pageParm.pageSize = that.pagination.pageSize;
      api.responsibilityList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    toEdit(record) {
      this.$refs.responsibilityModel.edit(record);
    },
    toAdd() {
      this.$refs.responsibilityModel.add(this.diseaseTypeList);
    },
    responsibilityModelOk() {
      this.loadData();
    },
  }
};
</script>
