<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="" name="medicalDirectoryType">
        <a-select v-model:value="form.medicalDirectoryType" :allowClear="true" style="width: 150px"
                  @change="searchQuery"
                  :default-value="{ key: 1 }">
          <a-select-option v-for="item in medicalDirectoryOption" :key="item.code" :value="item.code">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="医保名称" name="name">
        <a-input v-model:value="form.name" class="query-condition"/>
      </a-form-item>
      <a-form-item label="医保代码" name="code">
        <a-input v-model:value="form.code" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns1"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}"
          v-show="form.medicalDirectoryType == 1">
      </a-table>

      <a-table
          bordered
          :columns="columns2"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}"
          v-show="form.medicalDirectoryType == 2">
      </a-table>

      <a-table
          bordered
          :columns="columns3"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}"
          v-show="form.medicalDirectoryType == 3">
      </a-table>

      <a-table
          bordered
          :columns="columns4"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}"
          v-show="form.medicalDirectoryType == 4">
      </a-table>

      <a-table
          bordered
          :columns="columns5"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}"
          v-show="form.medicalDirectoryType == 5">
      </a-table>

    </div>

  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'MedicalDirectoryList',
  mixins: [CommonMixin],
  components: {},
  data() {
    let columns1 = [
      {
        title: '序号',
        dataIndex: 'id',
        align: 'center',
        width: '2%',
        customRender: ({index}) => {
          return index + 1;
        }
      },
      {
        title: '大类名称',
        dataIndex: 'dnmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '国家中药代码',
        dataIndex: 'gjzydm',
        width: '4%',
        align: 'center',
      },
      {
        title: '中药名称',
        dataIndex: 'zymc',
        width: '5%',
        align: 'center',
      },
      {
        title: '药材名称',
        dataIndex: 'ycmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '功效分类',
        dataIndex: 'gxfl',
        width: '5%',
        align: 'center',
      },
      {
        title: '功能与主治',
        dataIndex: 'gnyzz',
        width: '10%',
        align: 'center',
      },
      {
        title: '用法与用量',
        dataIndex: 'yfyyl',
        width: '4%',
        align: 'center',
      },
      {
        title: '政策标识',
        dataIndex: 'yblx',
        width: '2%',
        align: 'center',
      },
    ];

    let columns2 = [
      {
        title: '序号',
        dataIndex: 'id',
        align: 'center',
        width: '2%',
        customRender: ({index}) => {
          return index + 1;
        }
      },
      {
        title: '大类名称',
        dataIndex: 'dnmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '国家药品代码',
        dataIndex: 'gjypdm',
        width: '4%',
        align: 'center',
      },
      {
        title: '注册名称',
        dataIndex: 'zcmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '商品名称',
        dataIndex: 'spmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '注册剂型',
        dataIndex: 'scjx',
        width: '10%',
        align: 'center',
      },
      {
        title: '实际剂型',
        dataIndex: 'sjjx',
        width: '4%',
        align: 'center',
      },
      {
        title: '注册规格',
        dataIndex: 'zcgg',
        width: '4%',
        align: 'center',
      },
      {
        title: '实际规格',
        dataIndex: 'sjgg',
        width: '4%',
        align: 'center',
      },
      {
        title: '药品企业',
        dataIndex: 'ypqy',
        width: '4%',
        align: 'center',
      },
      {
        title: '政策标识',
        dataIndex: 'yblx',
        width: '3%',
        align: 'center',
      },
      {
        title: '备注',
        dataIndex: 'bz',
        width: '4%',
        align: 'center',
      },
    ];

    let columns3 = [
      {
        title: '序号',
        dataIndex: 'id',
        align: 'center',
        width: '2%',
        customRender: ({index}) => {
          return index + 1;
        }
      },
      {
        title: '医保耗材代码',
        dataIndex: 'ybhcdm',
        width: '5%',
        align: 'center',
      },
      {
        title: '一级分类',
        dataIndex: 'yjfl',
        width: '4%',
        align: 'center',
      },
      {
        title: '二级分类',
        dataIndex: 'ejfl',
        width: '5%',
        align: 'center',
      },
      {
        title: '三级分类',
        dataIndex: 'sjfl',
        width: '5%',
        align: 'center',
      },
      {
        title: '医保通用名',
        dataIndex: 'ybtym',
        width: '5%',
        align: 'center',
      },
      {
        title: '材质',
        dataIndex: 'cz',
        width: '4%',
        align: 'center',
      },
      {
        title: '特征',
        dataIndex: 'tz',
        width: '4%',
        align: 'center',
      },
      {
        title: '耗材企业',
        dataIndex: 'hcqy',
        width: '4%',
        align: 'center',
      },
      {
        title: '耗材类型',
        dataIndex: 'hclx',
        width: '4%',
        align: 'center',
      },
      {
        title: '政策标识',
        dataIndex: 'yblx',
        width: '3%',
        align: 'center',
      },
    ];

    let columns4 = [
      {
        title: '序号',
        dataIndex: 'id',
        align: 'center',
        width: '2%',
        customRender: ({index}) => {
          return index + 1;
        }
      },
      {
        title: '国家医疗服务项目代码',
        dataIndex: 'xmdbqg',
        width: '5%',
        align: 'center',
      },
      {
        title: '国家医疗服务项目名称',
        dataIndex: 'xmmcqg',
        width: '4%',
        align: 'center',
      },
      {
        title: '地方医疗服务项目代码',
        dataIndex: 'xmdbwh',
        width: '5%',
        align: 'center',
      },
      {
        title: '地方医疗服务项目名称',
        dataIndex: 'xmmcwh',
        width: '5%',
        align: 'center',
      },
      {
        title: '项目内涵',
        dataIndex: 'xmnh',
        width: '10%',
        align: 'center',
      },
      {
        title: '除外内容',
        dataIndex: 'cwnr',
        width: '4%',
        align: 'center',
      },
      {
        title: '收费类别',
        dataIndex: 'sflb',
        width: '4%',
        align: 'center',
      },
      {
        title: '政策标识',
        dataIndex: 'yblx',
        width: '3%',
        align: 'center',
      },
    ];

    let columns5 = [
      {
        title: '序号',
        dataIndex: 'id',
        align: 'center',
        width: '2%',
        customRender: ({index}) => {
          return index + 1;
        }
      },
      {
        title: '制剂代码',
        dataIndex: 'zjdm',
        width: '5%',
        align: 'center',
      },
      {
        title: '申请人单位名称',
        dataIndex: 'sqrdwmc',
        width: '4%',
        align: 'center',
      },
      {
        title: '制剂类别',
        dataIndex: 'zjlb',
        width: '5%',
        align: 'center',
      },
      {
        title: '制剂名称',
        dataIndex: 'zjmc',
        width: '5%',
        align: 'center',
      },
      {
        title: '剂型',
        dataIndex: 'jx',
        width: '3%',
        align: 'center',
      },
      {
        title: '规格',
        dataIndex: 'gg',
        width: '4%',
        align: 'center',
      },
      {
        title: '适应症',
        dataIndex: 'syz',
        width: '10%',
        align: 'center',
      },
      {
        title: '用法用量',
        dataIndex: 'yfyl',
        width: '8%',
        align: 'center',
      },
      {
        title: '儿童用药',
        dataIndex: 'rtyy',
        width: '4%',
        align: 'center',
      },
      {
        title: '老年患者用药',
        dataIndex: 'lnhzyy',
        width: '4%',
        align: 'center',
      },
      {
        title: '政策标识',
        dataIndex: 'yblx',
        width: '3%',
        align: 'center',
      },
    ];


    return {
      form: {
        medicalDirectoryType: 1
      },
      rowKey: "id",
      columns1,
      columns2,
      columns3,
      columns4,
      columns5,
      dataSource: [],
      loading: false,
      medicalDirectoryOption: [{"code": 1, "name": "中药"}, {"code": 2, "name": "西药中成药"}, {
        "code": 3,
        "name": "医用耗材"
      }, {"code": 4, "name": "医疗服务项目"}, {"code": 5, "name": "医疗机构制剂"}],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
      this.loadData();
    },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageParm = {};
      that.form.pageParm.pageNum = that.pagination.current;
      that.form.pageParm.pageSize = that.pagination.pageSize;
      api.queryMedicalDirectoryList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
  }
};
</script>
