<template>
  <div style="height: 100%;">
    <IndexSzzy v-if="hasPermission('index_szzy')"/>
    <IndexDefault v-else/>
  </div>
</template>

<script>
import IndexDefault from "./IndexDefault";
import IndexSzzy from "./IndexSzzy";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'Index',
  mixins: [CommonMixin],
  components: {
    IndexDefault,
    IndexSzzy,
  },
};
</script>
