<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="医院编码" name="hospitalCode">
          <a-input v-model:value="form.hospitalCode"/>
        </a-form-item>
        <a-form-item label="医院名称" name="hospitalName">
          <a-input v-model:value="form.hospitalName"/>
        </a-form-item>
        <a-form-item label="机构等级" name="orgLevel">
          <a-select v-model:value="form.orgLevel" :allowClear="true">
            <a-select-option v-for="item in dictData.orgLevelData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="机构类别" name="orgCategory">
          <a-select v-model:value="form.orgCategory" :allowClear="true">
            <a-select-option v-for="item in dictData.orgCategoryData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在省" name="provinceId">
          <a-select v-model:value="form.provinceId" @change="handleProvinceChange" :showSearch="true"
                    :filterOption="selectFilterOption" optionFilterProp="children">
            <a-select-option v-for="item in provinceData" :key="item.areaId" :value="item.areaId">
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在市" name="cityId">
          <a-select v-model:value="form.cityId" @change="handleCityChange" :showSearch="true"
                    :filterOption="selectFilterOption" optionFilterProp="children">
            <a-select-option v-for="item in cityData" :key="item.areaId" :value="item.areaId">
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在区" name="districtId">
          <a-select v-model:value="form.districtId" :showSearch="true" :filterOption="selectFilterOption"
                    optionFilterProp="children">
            <a-select-option v-for="item in districtData" :key="item.areaId" :value="item.areaId">
              {{ item.areaName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="详细地址" name="address">
          <a-input v-model:value="form.address"/>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="saveHospital" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import AreaMixin from "@/mixin/AreaMixin";

export default {
  name: 'StandardHospitalModel',
  mixins: [CommonMixin, AreaMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      type: 0,
      form: {
        hospitalCode: "",
        hospitalName: "",
        orgLevel: null,
        orgCategory: null,
        provinceId: null,
        cityId: null,
        districtId: null,
        address: "",
      },
      rules: {
        hospitalName: [
          {required: true, message: '请输入医院名称！', trigger: 'blur'},
        ],
        hospitalCode: [
          {required: true, message: '请输入医院编码！', trigger: 'blur'},
        ],
        provinceId: [
          {required: true, message: '请选择所在省！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_standard_hospital");
    },
    close() {
      this.visible = false;
      this.$emit('ok');
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    add() {
      this.visible = true;
      this.type = 1;
      this.title = '新增';
    },
    edit(record) {
      this.visible = true;
      this.type = 2;
      this.title = '编辑';
      let data = {...record};
      if (data.provinceId) {
        this.cityData = this.provinceData.find(function (item) {
          return item.areaId === data.provinceId;
        }).children;
        if (data.cityId) {
          this.districtData = this.cityData.find(function (item) {
            return item.areaId === data.cityId;
          }).children;
        }
      }
      this.form = data;
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    saveHospital() {
      // 保存
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        param.hospitalType = 1;

        that.spinning = true;
        api.hospitalSave(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },

  }
}
</script>