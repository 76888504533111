<template>
  <a-config-provider :locale="locale">
    <PageLayout ref="pageLayout" v-if="$store.state.user.logged"/>
    <Login v-if="!$store.state.user.logged"/>

    <a-modal v-model:visible="loginVisible" title="登录" :closable="false" :keyboard="false" :maskClosable="false"
             :footer="null" :afterClose="loginAfterClose">
      <a-form ref="loginForm" :model="loginForm" :rules="loginRules" :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }">
        <a-form-item label="用户名" name="operatorName">
          <a-input v-model:value="loginForm.operatorName"/>
        </a-form-item>
        <a-form-item label="密码" name="password">
          <a-input v-model:value="loginForm.password" type="password"/>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="loginClose">取消</a-button>
        <a-button type="primary" @click="loginSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-modal>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import PageLayout from "@/view/layout/PageLayout";
import Login from "@/view/login/Login";
import api from "@/api";
import constant from "@/store/constant";

dayjs.locale('zh-cn');

export default {
  name: 'App',
  components: {PageLayout, Login},
  watch: {
    "$store.state.user.loginTimestamp": function () {
      this.loginVisible = true;
    },
  },
  data() {
    return {
      locale: zhCN,
      loginVisible: false,
      loginForm: {
        operatorName: '',
        password: ''
      },
      loginRules: {
        operatorName: [
          {required: true, message: "请输入用户名！", trigger: "blur"},
        ],
        password: [
          {required: true, message: "请输入密码！", trigger: "blur"},
        ],
      },
    };
  },
  methods: {
    loginClose() {
      this.loginVisible = false;
    },
    loginAfterClose() {
      this.$refs.loginForm.resetFields();
    },
    loginSubmit() {
      let that = this;
      that.$refs.loginForm.validate().then(() => {
        that.loading = true;
        api.login(that.loginForm).then(res => {
          that.loading = false;
          if (res.code != that.$store.state.app.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }

          // 登录成功
          that.$message.success('登录成功');
          that.loginClose();
          sessionStorage.setItem(constant.LOGIN_DATA, JSON.stringify(res.data));
          if (that.$store.state.user.operator && that.$store.state.user.operator.operatorId === res.data.operator.operatorId) {
            that.$store.commit("SET_TOKEN", res.data.token);
          } else {
            that.$refs.pageLayout.clearNavigation();
            that.$store.commit("SET_LOGIN_DATA", res.data);
          }
        }).catch(() => {
          that.loading = false;
          that.$message.error('请求异常！');
        });
      });
    }
  },
}
</script>
