<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="角色名称" name="roleName">
        <a-input v-model:value="form.roleName" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="toAddOrgRole">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEditOrgRole(record)">编辑</a>
            <a-divider type="vertical"/>
            <a @click="toMenuPermission(record)">分配权限</a>
            <a-divider type="vertical"/>
            <a-popconfirm title="确认删除?" @confirm="() => handleDelete(record)">
              <a>删除</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>
    </div>

    <OrgRoleModal ref="roleModal" @ok="roleModalOk"/>
    <MenuPermission ref="menuPermission" @saveMenuPermission="saveMenu"/>
  </div>
</template>

<script>
import api from "@/api";
import OrgRoleModal from './OrgRoleModal';
import MenuPermission from "../orgconf/MenuPermission";
import CommonMixin from "@/mixin/CommonMixin";

const columns = [
  {
    title: '角色名称',
    dataIndex: 'roleName',
    width: 150,
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 150,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 150,
    align: 'center',
  },
];

export default {
  name: 'RoleManager',
  mixins: [CommonMixin],
  components: {
    OrgRoleModal,
    MenuPermission,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      form: {
        roleName: '',
      },
      rowKey: 'roleId',
      columns,
      dataSource: [],
      loading: false,
      currentRecord: null,
    }
  },
  methods: {
    formData() {
      return this.form;
    },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      api.orgRoleList(that.formData()).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    toAddOrgRole() {
      this.$refs.roleModal.add();
    },
    toEditOrgRole(record) {
      this.$refs.roleModal.edit(record);
    },
    roleModalOk() {
      this.loadData();
    },
    handleDelete(record) {
      let that = this;
      api.sysRoleDelete({roleId: record.roleId}).then((res) => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    toMenuPermission(record) {
      this.currentRecord = record;
      api.queryMenuByCurrentOperator({"roleId": record.roleId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.$refs.menuPermission.show(res.data, 'comptainer_insurance_org_manager');
      }).catch(() => {
        this.$message.error("初始化菜单选项失败");
      });
    },
    saveMenu(sysResourceIdList) {
      api.saveOrgMgrMenuInfo({
        "roleId": this.currentRecord.roleId,
        "sysResourceIdList": sysResourceIdList
      }).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success(this.apiStatus.defaultSuccMsg);
        this.$refs.menuPermission.close();
      }).catch(() => {
        this.$message.error("保存权限失败");
      });
    }
  }
}
</script>







