<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose" :width="1000">
    <a-spin :spinning="spinning">
      <a-card title="基础资料" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <template #extra>
          <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                    @change="addBaseMaterial">
            <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                             :value="material.id">
              {{ material.name }}
            </a-select-option>
          </a-select>
        </template>

        <a-table bordered :columns="columns" :data-source="materialConfigData.baseMaterialList"
                 :pagination="false" rowKey="id">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确认删除?" @confirm="() => deleteBaseMaterial(record)">
                <a>删除</a>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </a-card>

      <a-card title="出险原因" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <a-card v-for="accident in materialConfigData.accidentReasonList" :key="accident.id" :title="accident.name">
          <template #extra>
            <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                      @change="addAccidentReasonMaterial">
              <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                               :value="concatValue(accident.id,material.id)">
                {{ material.name }}
              </a-select-option>
            </a-select>
          </template>

          <a-table bordered :columns="columns" :data-source="accident.accidentReasonMaterialList"
                   :pagination="false" rowKey="id">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'operation'">
                <a-popconfirm title="确认删除?" @confirm="() => deleteAccidentReasonMaterial(record)">
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-card>
      </a-card>

      <a-card title="申请事项" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <a-card v-for="apply in materialConfigData.accidentApplyItemList" :key="apply.id"
                :title="concatValue(apply.accidentReasonName,apply.applyItemName)">
          <template #extra>
            <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                      @change="addApplyItemMaterial">
              <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                               :value="concatValue(apply.accidentReasonId,apply.applyItemId,material.id)">
                {{ material.name }}
              </a-select-option>
            </a-select>
          </template>

          <a-table bordered :columns="columns" :data-source="apply.applyItemMaterialList" :pagination="false"
                   rowKey="id">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'operation'">
                <a-popconfirm title="确认删除?" @confirm="() => deleteApplyItemMaterial(record)">
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-card>
      </a-card>

      <a-card title="申请人身份" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <a-card v-for="applicant in materialConfigData.applicantIdentityList" :key="applicant.id"
                :title="applicant.name">
          <template #extra>
            <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                      @change="addApplicantIdentityMaterial">
              <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                               :value="concatValue(applicant.id,material.id)">
                {{ material.name }}
              </a-select-option>
            </a-select>
          </template>

          <a-table bordered :columns="columns" :data-source="applicant.applicantIdentityMaterialList"
                   :pagination="false" rowKey="id">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'operation'">
                <a-popconfirm title="确认删除?" @confirm="() => deleteApplicantIdentityMaterial(record)">
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </a-card>
      </a-card>

      <a-card title="保单原件" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <template #extra>
          <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                    @change="addPolicyMaterial">
            <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                             :value="material.id">
              {{ material.name }}
            </a-select-option>
          </a-select>
        </template>

        <a-table bordered :columns="columns" :data-source="materialConfigData.policyMaterialList"
                 :pagination="false" rowKey="id">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确认删除?" @confirm="() => deletePolicyMaterial(record)">
                <a>删除</a>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </a-card>

      <a-card title="委托" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <template #extra>
          <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                    @change="addEntrustMaterial">
            <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                             :value="material.id">
              {{ material.name }}
            </a-select-option>
          </a-select>
        </template>

        <a-table bordered :columns="columns" :data-source="materialConfigData.entrustMaterialList"
                 :pagination="false" rowKey="id">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确认删除?" @confirm="() => deleteEntrustMaterial(record)">
                <a>删除</a>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </a-card>

      <a-card title="权益转让" :headStyle="headStyle" :bodyStyle="bodyStyle">
        <template #extra>
          <a-select placeholder="添加资料" class="material-select" :allowClear="true"
                    @change="addTransferMaterial">
            <a-select-option v-for="material in materialConfigData.materialList" :key="material.id"
                             :value="material.id">
              {{ material.name }}
            </a-select-option>
          </a-select>
        </template>

        <a-table bordered :columns="columns" :data-source="materialConfigData.transferMaterialList"
                 :pagination="false" rowKey="id">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
              <a-popconfirm title="确认删除?" @confirm="() => deleteTransferMaterial(record)">
                <a>删除</a>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </a-card>

      <div class="form-bootom-button" style="padding-top: 20px;">
        <a-button type="button" @click="close">关闭</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import api from "@/api";

const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '资料名称',
    dataIndex: 'materialName',
    width: 200,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 80,
    align: 'center',
  },
];

export default {
  name: 'MaterialConfigModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      unitId: null,
      headStyle: {textAlign: 'center', fontSize: '20px', fontWeight: 'bold'},
      bodyStyle: {padding: '50px'},
      columns,
      materialConfigData: {
        materialList: [],
        accidentReasonList: [],
        accidentApplyItemList: [],
        applicantIdentityList: [],
        policyMaterialList: [],
        entrustMaterialList: [],
        transferMaterialList: []
      }
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claims_guide_material_config");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    loadData() {
      let that = this;
      that.spinning = true;
      api.materialConfigData(that.unitId).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.materialConfigData = res.data;
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    concatValue(v1, v2, v3) {
      let value = v1;
      if (v2) {
        value += '-' + v2;
      }
      if (v3) {
        value += '-' + v3;
      }
      return value;
    },
    show(unitId, unitName) {
      this.unitId = unitId;
      this.visible = true;
      this.title = unitName + ' - 资料配置';
      this.loadData();
    },
    addAccidentReasonMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let vl = value.split("-");
      let parm = {
        unitId: that.unitId,
        accidentReasonId: vl[0],
        materialId: vl[1]
      };
      that.spinning = true;
      api.addClaimsGuideAccidentReasonMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteAccidentReasonMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideAccidentReasonMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addApplyItemMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let vl = value.split("-");
      let parm = {
        unitId: that.unitId,
        accidentReasonId: vl[0],
        applyItemId: vl[1],
        materialId: vl[2]
      };
      that.spinning = true;
      api.addClaimsGuideApplyItemMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteApplyItemMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideApplyItemMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addApplicantIdentityMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let vl = value.split("-");
      let parm = {
        unitId: that.unitId,
        applicantIdentityId: vl[0],
        materialId: vl[1]
      };
      that.spinning = true;
      api.addClaimsGuideApplicantIdentityMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteApplicantIdentityMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideApplicantIdentityMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addPolicyMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let parm = {
        unitId: that.unitId,
        materialId: value
      };
      that.spinning = true;
      api.addClaimsGuidePolicyMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deletePolicyMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuidePolicyMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addEntrustMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let parm = {
        unitId: that.unitId,
        materialId: value
      };
      that.spinning = true;
      api.addClaimsGuideEntrustMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteEntrustMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideEntrustMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addTransferMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let parm = {
        unitId: that.unitId,
        materialId: value
      };
      that.spinning = true;
      api.addClaimsGuideTransferMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteTransferMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideTransferMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addBaseMaterial(value) {
      if (!value) {
        return;
      }
      let that = this;
      let parm = {
        unitId: that.unitId,
        materialId: value
      };
      that.spinning = true;
      api.addClaimsGuideBaseMaterial(parm).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    deleteBaseMaterial(record) {
      let that = this;
      that.spinning = true;
      api.deleteClaimsGuideBaseMaterial(record.id).then(res => {
        that.spinning = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.spinning = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>

<style scoped>
.material-select {
  width: 260px;
  position: absolute;
  top: 16px;
  right: 24px;
}
</style>