<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="机构" name="unitId" v-if="hasPermission('claims_guide_material_all')">
        <a-select v-model:value="form.unitId" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称" name="name">
        <a-input v-model:value="form.name" class="query-condition"/>
      </a-form-item>
      <a-form-item label="资料类型" name="materialTypeId">
        <a-select v-model:value="form.materialTypeId" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in materialTypeList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="handleAdd">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical"/>
            <a-popconfirm title="确认删除?" @confirm="() => handleDelete(record)">
              <a>删除</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>
    </div>

    <MaterialModal ref="materialModal" @ok="materialModalOk"/>
  </div>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import api from "@/api";
import MaterialModal from './MaterialModal';


export default {
  name: 'MaterialList',
  mixins: [CommonMixin],
  components: {MaterialModal},
  created() {
    this.loadData();
    // 资料类型
    api.getClaimsGuideMaterialType().then(res => {
      if (res.code != this.apiStatus.success) {
        return;
      }
      this.materialTypeList = res.data;
    });
  },
  data() {
    return {
      form: {
        name: '',
        materialTypeId: null,
      },
      columns: this.createColumn(),
      dataSource: [],
      loading: false,
      materialTypeList: [],
    }
  },
  methods: {
    createColumn() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'rowIndex',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        }
      ];

      if (this.hasPermission('claims_guide_material_all')) {
        columns.push({
          title: '所属机构',
          dataIndex: 'unitName',
          width: 150,
          align: 'center',
        });
      }

      columns = columns.concat([
        {
          title: '名称',
          dataIndex: 'name',
          width: 150,
          align: 'center',
        },
        {
          title: '类型',
          dataIndex: 'materialTypeName',
          width: 150,
          align: 'center',
        },
        {
          title: '排序',
          dataIndex: 'seq',
          width: 100,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          align: 'center',
        }
      ]);
      return columns;
    },
    loadData() {
      let that = this;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.getClaimsGuideMaterialList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleAdd() {
      this.$refs.materialModal.add(this.materialTypeList);
    },
    handleEdit(record) {
      this.$refs.materialModal.edit(this.materialTypeList, record);
    },
    materialModalOk() {
      this.loadData();
    },
    handleDelete(record) {
      let that = this;
      api.deleteClaimsGuideMaterial(record.id).then((res) => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
