<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :width="modelWidth">
    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">档案信息</h3>
      <a-descriptions bordered>
        <a-descriptions-item label="医院名称" :span="1">{{ leaveHospital.hospitalName }}</a-descriptions-item>
        <a-descriptions-item label="医疗类别" :span="1">{{ leaveHospital.medicalName }}</a-descriptions-item>
        <a-descriptions-item label="就诊日期" :span="1">{{ leaveHospital.treatDate }}</a-descriptions-item>
        <a-descriptions-item label="出院日期" :span="1">{{ leaveHospital.leaveDate }}</a-descriptions-item>
        <a-descriptions-item label="住院天数" :span="1">{{ leaveHospital.hospitalDay }}</a-descriptions-item>
      </a-descriptions>
    </div>

    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">诊断列表</h3>
      <a-table :columns="leaveDiseaseColumns" :data-source="leaveDiseaseList" :rowKey="leaveDiseaseRowKey"
               :pagination="false"></a-table>
    </div>

    <div style="marginBottom: 25px" v-for="(item, index) in leaveRcptList" :key="index">
      <h3 :style="{ marginBottom: '10px' }">票据信息-{{ index + 1 }}</h3>
      <a-descriptions bordered :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
        <a-descriptions-item label="结算单据号">{{ item.settleNum }}</a-descriptions-item>
        <a-descriptions-item label="结算日期">{{ item.settleDate }}</a-descriptions-item>
        <a-descriptions-item label="医疗支付方式">{{ item.medicalInsuranceType }}</a-descriptions-item>
        <a-descriptions-item label="发票号">{{ item.invoiceNo }}</a-descriptions-item>
        <a-descriptions-item label="总费用">{{ item.sumMoney }}</a-descriptions-item>
        <a-descriptions-item label="社保起付线">{{ item.medicarePayLine }}</a-descriptions-item>
        <a-descriptions-item label="个人先支付">{{ item.selfBearAmnt }}</a-descriptions-item>
        <a-descriptions-item label="自付金额">{{ item.selfBAmnt }}</a-descriptions-item>
        <a-descriptions-item label="自费金额">{{ item.selfCAmnt }}</a-descriptions-item>
        <a-descriptions-item label="符合医保费用">{{ item.inInsureMoney }}</a-descriptions-item>
        <a-descriptions-item label="医院负担">{{ item.hosBearMoney }}</a-descriptions-item>
        <a-descriptions-item label="统筹支付">{{ item.fundMoney }}</a-descriptions-item>
        <a-descriptions-item label="个人自付">{{ item.perBearMoney }}</a-descriptions-item>
        <a-descriptions-item label="账户支付">{{ item.accountFundMoney }}</a-descriptions-item>
        <a-descriptions-item label="本次现金支付">{{ item.cashMoney }}</a-descriptions-item>
        <a-descriptions-item label="统筹分段自付">{{ item.sectionCoordinatePayMoney }}</a-descriptions-item>
        <a-descriptions-item label="超封顶线自付">{{ item.overCappingPayMoney }}</a-descriptions-item>
        <a-descriptions-item label="超限价自付金额">{{ item.selfPaidExpenses }}</a-descriptions-item>
        <a-descriptions-item label="公务员基金支付">{{ item.civilServantFundMoney }}</a-descriptions-item>
        <a-descriptions-item label="大病基金支付">{{ item.seriousFundMoney }}</a-descriptions-item>
        <a-descriptions-item label="民政救助支付">{{ item.civilSubsidy }}</a-descriptions-item>
        <a-descriptions-item label="大额支付">{{ item.bigPayMoney }}</a-descriptions-item>
        <a-descriptions-item label="其他基金支付">{{ item.otherFundMoney }}</a-descriptions-item>
      </a-descriptions>
    </div>
  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

const leaveDiseaseColumns = [
  {
    title: '序号',
    dataIndex: 'leaveDiseaseId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '诊断类别',
    dataIndex: 'diseaseType',
    width: '5%',
    customRender: ({text}) => {
      if (text == 'I') {
        return "入院诊断";
      }
      if (text == 'D') {
        return "出院诊断";
      }
      if (text == 'O') {
        return "门急诊诊断";
      }
    }
  },
  {
    title: '诊断代码',
    width: '5%',
    dataIndex: 'leaveIcdCode',
  },
  {
    title: '疾病名称',
    dataIndex: 'leaveIcdName',
    width: '5%',
  },
  {
    title: '是否主诊断',
    dataIndex: 'principalDiagnosis',
    width: '5%',
    customRender: ({text}) => {
      if (text == 'Y') {
        return "是";
      }
      if (text == 'N') {
        return "否";
      }
    }
  },
];

export default {
  name: 'LeaveHospitalModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      form: {},
      modelWidth: "98%",
      leaveHospital: {},
      leaveDiseaseList: [],
      leaveDiseaseColumns,
      leaveDiseaseRowKey: "leaveDiseaseId",
      leaveRcptList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claim_query");
    },
    close() {
      this.visible = false;
    },
    show(informationTransactionId) {
      this.visible = true;
      api.queryLeaveHospitalInfo({"informationTransactionId": informationTransactionId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.leaveHospital = res.data.leaveHospital;
        this.leaveDiseaseList = res.data.leaveDiseaseList;
        this.leaveRcptList = res.data.leaveRcptList;
      }).catch(() => {
        this.spinning = false;
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
