<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="所属机构" name="unitId">
          <a-select v-model:value="form.unitId" :showSearch="true" :filterOption="selectFilterOption"
                    optionFilterProp="children">
            <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="排查标记" name="riskFlagCode">
          <a-select v-model:value="form.riskFlagCode" :allowClear="true">
            <a-select-option v-for="item in riskFlagData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="排查结果" name="resultFlagCode">
          <a-select v-model:value="form.resultFlagCode" :allowClear="true">
            <a-select-option v-for="item in resultFlagData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="开始日期" name="beginDate">
          <a-date-picker v-model:value="form.beginDate"/>
        </a-form-item>
        <a-form-item label="结束日期" name="endDate">
          <a-date-picker v-model:value="form.endDate"/>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'ExportModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '导出',
      form: {
        unitId: null,
        resultFlagCode: null,
        beginDate: null,
        endDate: null,
      },
      rules: {
        unitId: [
          {required: true, message: '请选择机构！', trigger: 'blur'},
        ],
      },
      riskFlagData: [
        {code: "0", text: "高"},
        {code: "1", text: "中"},
        {code: "2", text: "低"},
        {code: "3", text: "中高"}
      ],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_auxiliary_before_risk");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    show() {
      this.visible = true;
    },
    formData() {
      let data = {...this.form};
      data.beginDate = data.beginDate ? data.beginDate.format("YYYY-MM-DD") : null;
      data.endDate = data.endDate ? data.endDate.format("YYYY-MM-DD") : null;
      return data;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        api.auxiliaryBeforeRiskExport(that.formData()).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          window.open(res.data);
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
