<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="医院编码" name="hospitalCode">
        <a-input v-model:value="form.hospitalCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="医院名称" name="hospitalName">
        <a-input v-model:value="form.hospitalName" class="query-condition"/>
      </a-form-item>
      <a-form-item label="是否对接" name="joinFlag">
        <a-select v-model:value="form.joinFlag" :allowClear="true" placeholder="请选择" style="width: 150px">
          <a-select-option value="1">是</a-select-option>
          <a-select-option value="0">否</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否关联" name="relationFlag">
        <a-select v-model:value="form.relationFlag" :allowClear="true" placeholder="请选择" style="width: 150px">
          <a-select-option value="1">是</a-select-option>
          <a-select-option value="0">否</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="关联医院编码" name="standardHospitalCode">
        <a-input v-model:value="form.standardHospitalCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="机构等级" name="orgLevel">
        <a-select v-model:value="form.orgLevel" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in dictData.orgLevelData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="机构类别" name="orgCategory">
        <a-select v-model:value="form.orgCategory" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in dictData.orgCategoryData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所在省" name="provinceId">
        <a-select v-model:value="form.provinceId" :allowClear="true" @change="handleProvinceChange"
                  class="query-condition"
                  :showSearch="true" :filterOption="selectFilterOption" optionFilterProp="children">
          <a-select-option v-for="item in provinceData" :key="item.areaId" :value="item.areaId">
            {{ item.areaName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所在市" name="cityId">
        <a-select v-model:value="form.cityId" :allowClear="true" @change="handleCityChange" class="query-condition"
                  :showSearch="true" :filterOption="selectFilterOption" optionFilterProp="children">
          <a-select-option v-for="item in cityData" :key="item.areaId" :value="item.areaId">
            {{ item.areaName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所在区" name="districtId">
        <a-select v-model:value="form.districtId" :allowClear="true" class="query-condition" :showSearch="true"
                  :filterOption="selectFilterOption" optionFilterProp="children">
          <a-select-option v-for="item in districtData" :key="item.areaId" :value="item.areaId">
            {{ item.areaName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="toAddHospital">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEditHospital(record)">编辑</a>
            <a-divider type="vertical"/>
            <a-popconfirm v-if="record.joinFlag == 1" title="取消对接?" @confirm="() => editJoin(0, record.hospitalId)">
              <a style="color:red">取消对接</a>
            </a-popconfirm>
            <a-popconfirm v-if="record.joinFlag == 0" title="确认对接?" @confirm="() => editJoin(1, record.hospitalId)">
              <a>确认对接</a>
            </a-popconfirm>
            <a-divider type="vertical"/>
            <a-popconfirm v-if="record.showFlag == 1" title="取消展示?" @confirm="() => editShow(0, record.hospitalId)">
              <a style="color:red">取消展示</a>
            </a-popconfirm>
            <a-popconfirm v-if="record.showFlag == 0" title="确认展示?" @confirm="() => editShow(1, record.hospitalId)">
              <a>确认展示</a>
            </a-popconfirm>
            <a-divider type="vertical"/>
            <a @click="showRelation(record)">关联</a>
          </template>
        </template>

      </a-table>
    </div>

    <MedicalPlatformModel ref="medicalPlatformModel" @ok="medicalPlatformModelOk"/>
    <RelationModel ref="relationModel" @ok="closeRelation"/>
  </div>

</template>
<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import AreaMixin from "@/mixin/AreaMixin";
import MedicalPlatformModel from './MedicalPlatformModel';
import RelationModel from './RelationModel';

export default {
  name: 'MedicalPlatform',
  mixins: [CommonMixin, AreaMixin],
  components: {
    MedicalPlatformModel, RelationModel
  },
  data() {
    return {
      form: {
        hospitalCode: "",
        hospitalName: "",
        joinFlag: null,
        relationFlag: null,
        standardHospitalCode: "",
        orgLevel: null,
        orgCategory: null,
        provinceId: null,
        cityId: null,
        districtId: null,
      },
      dataSource: [],
      columns: this.buildColumns(),
      rowKey: 'hospitalId',
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    buildColumns() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'hospitalId',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        },
        {
          title: '医院编码',
          dataIndex: 'hospitalCode',
          width: 150,
        },
        {
          title: '医院名称',
          dataIndex: 'hospitalName',
          width: 250,
        },
        {
          title: '机构等级',
          dataIndex: 'orgLevelName',
          width: 120,
        },
        {
          title: '机构类别',
          dataIndex: 'orgCategoryName',
          width: 120,
        },
        {
          title: '所在省',
          dataIndex: 'provinceName',
          width: 120,
        },
        {
          title: '所在市',
          dataIndex: 'cityName',
          width: 120,
        },
        {
          title: '所在区',
          dataIndex: 'districtName',
          width: 120,
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          width: 150,
        },
        {
          title: '关联医院',
          dataIndex: 'handHospitalName',
          width: 300,
        },
        {
          title: '是否对接',
          dataIndex: 'joinFlag',
          width: 80,
          customRender: ({text}) => {
            return text == 1 ? "是" : "否";
          }
        },
        {
          title: '是否展示',
          dataIndex: 'showFlag',
          width: 80,
          customRender: ({text}) => {
            return text == 1 ? "是" : "否";
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 250,
        },
      ];
      return columns;
    },
    formData() {
      return this.form;
    },
    loadData() {
      let that = this;
      that.loading = true;
      let param = {...this.form};
      param.pageNum = that.pagination.current;
      param.pageSize = that.pagination.pageSize;
      param.hospitalType = 3;
      api.hospitalList(param).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    medicalPlatformModelOk() {
      this.loadData();
    },
    toAddHospital() {
      this.$refs.medicalPlatformModel.add();
    },
    toEditHospital(record) {
      this.$refs.medicalPlatformModel.edit(record);
    },
    editJoin(joinFlag, hospitalId) {
      let param = {"joinFlag": joinFlag, "hospitalId": hospitalId};
      api.editJoinFlagOrShowFlag(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    editShow(showFlag, hospitalId) {
      let param = {"showFlag": showFlag, "hospitalId": hospitalId};
      api.editJoinFlagOrShowFlag(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    showRelation(record) {
      this.$refs.relationModel.show(record.hospitalId, record.hospitalName);
    },
    closeRelation() {
      this.loadData();
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
