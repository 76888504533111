<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose" :width="width" :bodyStyle="bodyStyle">
    <a-table
        bordered
        :columns="columns"
        :data-source="dataSource"
        :rowKey="rowKey"
        :components="tableDraggableHeader"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="loading"
        :scroll="{ x: 'max-content'}">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a @click="handleDetail(record)">详情</a>
        </template>
      </template>

    </a-table>

    <RecordDetail ref="recordDetail"/>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import api from "@/api";
import RecordDetail from './RecordDetail';


export default {
  name: 'RecordList',
  mixins: [CommonMixin],
  components: {
    RecordDetail
  },
  data() {
    return {
      visible: false,
      title: '就医记录',
      width: '90%',
      bodyStyle: {
        minHeight: '500px',
        overflow: 'auto',
      },
      rowKey: 'medicalRecordId',
      columns: this.createColumn(),
      dataSource: [],
      loading: false,
      resultId: '',
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_auxiliary_after_precise");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    createColumn() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'rowIndex',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        },
      ];

      if (this.hasPermission('auxiliary_after_precise_all')) {
        columns.push({
          title: '数据源',
          dataIndex: 'dataSourceName',
          width: 120,
          align: 'center',
        });
      }

      columns = columns.concat([
        {
          title: '医院名称',
          dataIndex: 'hospitalName',
          width: 200,
          align: 'center',
        },
        {
          title: '就诊科室',
          dataIndex: 'department',
          width: 150,
          align: 'center',
        },
        {
          title: '就诊类型',
          dataIndex: 'visitTypeName',
          width: 90,
          align: 'center',
        },
        {
          title: '账单总金额',
          dataIndex: 'amount',
          width: 120,
          align: 'center',
        },
        {
          title: '排查标记',
          dataIndex: 'positiveFlagName',
          width: 120,
          align: 'center',
        },
        {
          title: '就诊时间',
          dataIndex: 'visitDateStr',
          width: 170,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          align: 'center',
        },
      ]);
      return columns;
    },
    show(resultId) {
      this.visible = true;
      this.resultId = resultId;
      this.loadData();
    },
    loadData() {
      let that = this;
      let parm = {
        resultId: that.resultId,
        pageNum: that.pagination.current,
        pageSize: that.pagination.pageSize
      };
      that.loading = true;
      api.getAfterPreciseMedicalRecordPageList(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleDetail(record) {
      this.$refs.recordDetail.show(record.medicalRecordId);
    }
  }
}
</script>
