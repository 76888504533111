<template>
  <div class="comptent">
    <a-modal :getContainer="getContainer" v-model:visible="visible" title="配置接口" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="500" :zIndex="2">
      <a-tree v-if="treeData.length>0" :tree-data="treeData" v-model:checkedKeys="checkedLeafKeys" :checkable="true"
              :defaultExpandAll="true" @check="onCheck"/>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'HealthTransaction',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      rowKey: 'transactionId',
      treeData: [],
      checkedLeafKeys: [],
      checkedAllKeys: [],
    };
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_insurance_org_conf");
    },
    close() {
      this.visible = false;
    },
    show(isShow, healthTranData) {
      this.visible = isShow;
      this.treeData = healthTranData.treeData;
      this.checkedLeafKeys = healthTranData.checkedLeafKeys;
      this.checkedAllKeys = healthTranData.checkedAllKeys;
    },
    onCheck(checkedLeafKeys, e) {
      this.checkedAllKeys = checkedLeafKeys.concat(e.halfCheckedKeys);
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    save() {
      this.$emit('saveHealthTransaction', this.checkedAllKeys);
      this.close();
    },
  }
};
</script>
