<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" title="机构操作员列表" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="modalWidth" :zIndex="1">

      <div class="table-wrapper">

        <div class="table-tools">
          <a-button type="primary" @click="toAddOperator(null)">
            <template #icon>
              <PlusOutlined/>
            </template>
            新增管理员
          </a-button>
        </div>

        <a-table
            bordered
            :columns="columns"
            :data-source="dataSource"
            :rowKey="rowKey"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            :defaultExpandAllRows="true"
            size="middle"
            :scroll="{ x: 'max-content'}">

          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
              <span v-show="record.orgMgrFlag ==1">
                <a @click="toEditOperator(record)">编辑</a>
                <a @click="updateDisableOrEnable(record.operatorId, 1)" v-show="record.disableFlag ==0"
                   style="margin-left: 5px; color:red">停用</a>
                <a @click="updateDisableOrEnable(record.operatorId, 0)" v-show="record.disableFlag ==1"
                   style="margin-left: 5px;">启用</a>
                <a @click="resetPassword(record.operatorId)" style="margin-left: 5px; color:red">重置密码</a>
              </span>
            </template>
          </template>

        </a-table>

        <OperatorModel ref="operatorModel" @saveCuccess="loadData"/>

      </div>

    </a-modal>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import OperatorModel from "./OperatorModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'operatorId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '5%',
    align: 'center',
  },
  {
    title: '账号',
    dataIndex: 'operatorName',
    width: '8%',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'cardNo',
    width: '9%',
    align: 'center',
  },
  {
    title: '联系方式',
    dataIndex: 'mobilePhone',
    width: '8%',
    align: 'center',
  },
  {
    title: '工号',
    dataIndex: 'jobNo',
    align: 'center',
    width: '8%',
  },
  {
    title: '角色',
    dataIndex: 'roleNames',
    width: '10%',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '8%',
    align: 'center',
  },
];

export default {
  name: 'OperatorInfoList',
  mixins: [CommonMixin],
  components: {
    OperatorModel,
  },
  data() {
    return {
      rowKey: 'operatorId',
      columns,
      visible: false,
      dataSource: [],
      loading: false,
      unitId: null,
      modalWidth: "100%"
    };
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_insurance_org_conf");
    },
    loadData() {
      let that = this;
      that.loading = true;
      let parm = {"pageNum": that.pagination.current, "pageSize": that.pagination.pageSize, "unitId": that.unitId};
      api.operatorList(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    showOperatorList(unitId) {
      this.visible = true;
      this.unitId = unitId;
      this.loadData();
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    close() {
      this.visible = false;
    },
    toAddOperator() {
      this.$refs.operatorModel.add(this.unitId);
    },
    toEditOperator(record) {
      this.$refs.operatorModel.edit(record);
    },
    updateDisableOrEnable(operatorId, disableFlag) {
      let param = {"operatorId": operatorId, "disableFlag": disableFlag};
      api.updateDisableOrEnable(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    resetPassword(operatorId) {
      let param = {"operatorId": operatorId};
      api.resetPassword(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
