<template>
  <div class="comptent">
    <div class="table-tools">
      <a-button type="primary" @click="toAddUnitInfo(null)">
        <template #icon>
          <PlusOutlined/>
        </template>
        新增
      </a-button>
    </div>

    <a-table
        v-if="dataSource.length>0"
        bordered
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :rowKey="rowKey"
        :loading="loading"
        :defaultExpandAllRows="true"
        size="middle"
        :scroll="{ x: 'max-content'}">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a @click="toEditUnitInfo(record)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="toAddUnitInfo(record.unitId)" style="margin-left: 5px">添加子机构</a>
          <a-divider type="vertical"/>
          <a @click="toOperatorList(record.unitId)" style="margin-left: 5px">操作员</a>
          <a-divider type="vertical"/>
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              更多
              <a-icon type="down"/>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a @click="auxiliaryArea(record.unitId)">辅助调查区域</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </template>

    </a-table>

    <UnitInfoModel ref="unitInfoModel" @saveCuccess="loadData"/>
    <OperatorInfoList ref="operatorInfoList"/>
    <AuxiliaryArea ref="auxiliaryArea"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import UnitInfoModel from "./UnitInfoModel";
import OperatorInfoList from "./OperatorInfoList";
import AuxiliaryArea from "./AuxiliaryArea";

const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 80,
    customRender: ({index, record}) => {
      return record.parentId == 0 ? index + 1 : null;
    }
  },
  {
    title: '机构编码',
    dataIndex: 'unitCode',
    width: 150,
    align: 'center',
  },
  {
    title: '机构名称',
    dataIndex: 'unitName',
    width: 300,
    align: 'center',
  },
  {
    title: '机构简称',
    dataIndex: 'unitAlias',
    width: 150,
    align: 'center',
  },
  {
    title: '行政归属地',
    dataIndex: 'address',
    width: 150,
    align: 'center',
  },
  {
    title: '参保日期标识',
    dataIndex: 'insureDateFlag',
    width: 120,
    align: 'center',
    customRender: ({text}) => {
      if (text === 1) {
        return '启用';
      } else {
        return '禁用';
      }
    }
  },
  {
    title: 'Token秘钥',
    dataIndex: 'appSecret',
    align: 'center',
    width: 300,
  },
  {
    title: '数据加密方法',
    dataIndex: 'encryptMethod',
    width: 120,
    align: 'center',
    customRender: ({text}) => {
      if (text === 1) {
        return '国寿SM4';
      } else if (text === 2) {
        return '平安SM4';
      } else {
        return '';
      }
    }
  },
  {
    title: '数据加密秘钥',
    dataIndex: 'encryptKey',
    width: 300,
    align: 'center',
  },
  {
    title: '是否控制接口',
    dataIndex: 'controlApiFlag',
    width: 120,
    align: 'center',
    customRender: ({text}) => {
      if (text === 1) {
        return '是';
      } else {
        return '否';
      }
    }
  },
  {
    title: '接口超时毫秒值',
    dataIndex: 'controlApiTime',
    width: 120,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 280,
    align: 'center',
  },
];

export default {
  name: 'InsuranceOrgConf',
  mixins: [CommonMixin],
  components: {
    UnitInfoModel,
    OperatorInfoList,
    AuxiliaryArea,
  },
  data() {
    return {
      rowKey: 'unitId',
      columns,
      dataSource: [],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      that.loading = true;
      api.insuranceOrgList().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    toAddUnitInfo(unitId) {
      this.$refs.unitInfoModel.add(unitId);
    },
    toEditUnitInfo(record) {
      this.$refs.unitInfoModel.edit(record);
    },
    toOperatorList(unitId) {
      this.$refs.operatorInfoList.showOperatorList(unitId);
    },
    auxiliaryArea(unitId) {
      this.$refs.auxiliaryArea.show(unitId);
    }
  }
};
</script>
