<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="机构" name="unitId" v-if="hasPermission('claims_guide_apply_item_all')">
        <a-select v-model:value="form.unitId" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称" name="name">
        <a-input v-model:value="form.name" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="handleAdd">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'icon'">
            <span style="display: inline-block;background-color: #027B6C;">
              <img :src="record.icon" style="width: 50px;height: 50px;"/>
            </span>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical"/>
            <a-popconfirm title="确认删除?" @confirm="() => handleDelete(record)">
              <a>删除</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>
    </div>

    <ApplyItemModal ref="applyItemModal" @ok="applyItemModalOk"/>
  </div>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import api from "@/api";
import ApplyItemModal from './ApplyItemModal';


export default {
  name: 'ApplyItemList',
  mixins: [CommonMixin],
  components: {ApplyItemModal},
  created() {
    this.loadData();
  },
  data() {
    return {
      form: {
        name: '',
      },
      columns: this.createColumn(),
      dataSource: [],
      loading: false,
    }
  },
  methods: {
    createColumn() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'rowIndex',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        }
      ];

      if (this.hasPermission('claims_guide_apply_item_all')) {
        columns.push({
          title: '所属机构',
          dataIndex: 'unitName',
          width: 150,
          align: 'center',
        });
      }

      columns = columns.concat([
        {
          title: '名称',
          dataIndex: 'name',
          width: 150,
          align: 'center',
        },
        {
          title: 'icon',
          dataIndex: 'icon',
          width: 150,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          align: 'center',
        }
      ]);
      return columns;
    },
    loadData() {
      let that = this;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.getClaimsGuideApplyItemList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleAdd() {
      this.$refs.applyItemModal.add();
    },
    handleEdit(record) {
      this.$refs.applyItemModal.edit(record);
    },
    applyItemModalOk() {
      this.loadData();
    },
    handleDelete(record) {
      let that = this;
      api.deleteClaimsGuideApplyItem(record.id).then((res) => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
