<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="姓名" name="realName">
        <a-input v-model:value="form.realName" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="toAddOperator(null)">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增操作员
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEditOperator(record)">编辑</a>
            <a @click="updateDisableOrEnable(record.operatorId, 1)" v-if="record.disableFlag ==0"
               style="margin-left: 5px; color:red">停用</a>
            <a @click="updateDisableOrEnable(record.operatorId, 0)" v-if="record.disableFlag ==1"
               style="margin-left: 5px;">启用</a>
            <a @click="resetPassword(record.operatorId)" style="margin-left: 5px; color:red">重置密码</a>
          </template>
        </template>

      </a-table>

      <OperatorModel ref="operatorModel" @saveCuccess="loadData"/>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import OperatorModel from "../orgconf/OperatorModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'operatorId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '5%',
    align: 'center',
  },
  {
    title: '账号',
    dataIndex: 'operatorName',
    width: '8%',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'cardNo',
    width: '9%',
    align: 'center',
  },
  {
    title: '联系方式',
    dataIndex: 'mobilePhone',
    width: '8%',
    align: 'center',
  },
  {
    title: '工号',
    dataIndex: 'jobNo',
    align: 'center',
    width: '8%',
  },
  {
    title: '角色',
    dataIndex: 'roleNames',
    width: '10%',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '8%',
    align: 'center',
  },
];

export default {
  name: 'InsuranceOrgConf',
  mixins: [CommonMixin],
  components: {
    OperatorModel,
  },
  data() {
    return {
      form: {
        realName: '',
      },
      rowKey: 'operatorId',
      columns,
      visible: false,
      dataSource: [],
      loading: false,
      modalWidth: "100%"
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      let parm = {
        "pageNum": that.pagination.current,
        "pageSize": that.pagination.pageSize,
        "orgMgrFlag": 0,
        "realName": this.form.realName
      };
      api.operatorList(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    close() {
      this.visible = false;
    },
    toAddOperator() {
      this.$refs.operatorModel.add(null, 'comptainer_insurance_org_operator_manager', 1);
    },
    toEditOperator(record) {
      this.$refs.operatorModel.edit(record, 'comptainer_insurance_org_operator_manager', 1);
    },
    updateDisableOrEnable(operatorId, disableFlag) {
      let param = {"operatorId": operatorId, "disableFlag": disableFlag};
      api.updateDisableOrEnable(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    resetPassword(operatorId) {
      let param = {"operatorId": operatorId};
      api.resetPassword(param).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.loadData();
        this.$message.success(this.apiStatus.defaultSuccMsg);
      }).catch(() => {
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
