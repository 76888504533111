const app = {
    state: {
        // 接口返回状态
        apiStatus: {
            fail: 0,
            success: 1,
            defaultErrMsg: '操作异常！',
            defaultSuccMsg: '操作成功！'
        },
        // 分页对象
        pagination: {
            current: 1,
            pageSize: 20,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            showTotal: total => `总共${total}条`,
            total: 0
        },
    },
    mutations: {},
    actions: {}
};

export default app;