<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="姓名" name="nameCode">
        <a-input v-model:value="form.nameCode" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          :defaultExpandAllRows="true"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="queryPdf(record.minioFileName)" v-show="record.uploadStatus == 2"
               style="margin-left: 5px; color:red">查看pdf</a>
          </template>
        </template>

      </a-table>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

const columns = [
  {
    title: '序号',
    dataIndex: 'operatorId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '交易流水号',
    dataIndex: 'transRefGuid',
    width: '6%',
    align: 'center',
  },
  {
    title: '身份证',
    dataIndex: 'userCode',
    width: '8%',
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'nameCode',
    width: '6%',
    align: 'center',
  },
  {
    title: '授权文件地址',
    dataIndex: 'url',
    width: '8%',
    align: 'center',
  },
  {
    title: '关联流水号',
    dataIndex: 'relevanceGuid',
    width: '10%',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '8%',
    align: 'center',
  },
  {
    title: '是否上传minio',
    dataIndex: 'uploadStatus',
    width: '5%',
    align: 'center',
    customRender: ({text}) => {
      if (text == 1) {
        return "未处理";
      } else if (text == 2) {
        return "已上传minio";
      } else if (text == 3) {
        return "处理失败";
      }
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '5%',
    align: 'center',
  },
];

export default {
  name: 'UserLicenseList',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      form: {
        nameCode: '',
      },
      rowKey: "id",
      columns,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      let parm = {
        "pageNum": that.pagination.current,
        "pageSize": that.pagination.pageSize,
        "nameCode": this.form.nameCode
      };
      api.userLicenseList(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    queryPdf(minioFileName) {
      let that = this;
      api.getPdfUrl({"minioFileName": minioFileName}).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        window.open(res.data);
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
