<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :width="modelWidth">
    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">费用明细</h3>
      <a-table :columns="archiveDiseaseColumns" :data-source="feeDetailList" :rowKey="rowKey"
               :pagination="pagination" @change="handleTableChange">
      </a-table>
    </div>

  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

const archiveDiseaseColumns = [
  {
    title: '序号',
    dataIndex: 'archivesDiseaseId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '费用明细唯一编号',
    dataIndex: 'medicalFeeSerialNumber',
    width: '5%',
  },
  {
    title: '医疗目录编码',
    width: '5%',
    dataIndex: 'hospitalCatalogCode',
  },
  {
    title: '医疗目录名称',
    dataIndex: 'hospitalCatalogName',
    width: '5%',
  },
  {
    title: '医疗项目类别',
    dataIndex: 'medicalClassification',
    width: '5%',
  },
  {
    title: '医疗项目等级',
    dataIndex: 'medicalChargeLevel',
    width: '5%',
  },
  {
    title: '单价',
    dataIndex: 'price',
    width: '5%',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    width: '5%',
  },
  {
    title: '金额',
    dataIndex: 'money',
    width: '5%',
  },
];

export default {
  name: 'FeeDetailModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      form: {},
      modelWidth: "98%",
      feeDetailList: [],
      archiveDiseaseColumns,
      rowKey: "feeDetailId",
      informationTransactionId: null,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claim_query");
    },
    close() {
      this.visible = false;
    },
    show(informationTransactionId) {
      this.informationTransactionId = informationTransactionId;
      this.visible = true;
      api.queryFeeDetailList({
        "informationTransactionId": informationTransactionId,
        "pageNum": this.pagination.current, "pageSize": this.pagination.pageSize
      }).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.feeDetailList = res.data.list;
        this.pagination.total = res.data.total;
      }).catch(() => {
        this.spinning = false;
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.show(this.informationTransactionId);
    },
  }
}
</script>
