<template>
  <div>
    <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
             :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800" :zIndex="1">
      <a-spin :spinning="spinning">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item name="currentPassword" label="旧密码" has-feedback>
            <a-input
                v-model:value="form.currentPassword"
                placeholder="请输入旧密码"
                type="password" autocomplete
            ></a-input>
          </a-form-item>
          <a-form-item name="password" label="新密码" has-feedback>
            <a-input
                v-model:value="form.password"
                placeholder="请输入新密码"
                type="password" autocomplete
            ></a-input>
          </a-form-item>
          <a-form-item name="confirmPassword" label="确认新密码" has-feedback>
            <a-input
                v-model:value="form.confirmPassword"
                placeholder="请再次输入新密码"
                type="password" autocomplete
            ></a-input>
          </a-form-item>

        </a-form>

        <div class="form-bootom-button">
          <a-button type="button" @click="close">取消</a-button>
          <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
        </div>

      </a-spin>
    </a-modal>

  </div>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";
import constant from "@/store/constant";

export default {
  name: 'UnitInfoModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    // 表单验证
    let validateNewPassword = (rule, value, callback) => {
      if (value === this.form.currentPassword) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        callback();
      }
    };
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {},
      rules: {
        currentPassword: [
          {required: true, message: "请输入旧密码", trigger: "change"},
        ],
        password: [
          {required: true, message: "请输入新密码", trigger: "change"},
          {validator: validateNewPassword, trigger: "change"},
        ],
        confirmPassword: [
          {required: true, message: "请重新输入新密码", trigger: "change"},
          {validator: validateNewPassword2, trigger: "change"},
        ],
      },
      operatorId: null,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById('app');
    },
    close() {
      this.visible = false;
      this.$emit('saveCuccess');
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    edit(operatorId) {
      this.visible = true;
      this.title = '修改密码';
      this.operatorId = operatorId;
    },
    formData() {
      return this.form;
    },
    save() {
      // 保存
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        let param = that.formData();
        param.operatorId = this.operatorId;
        api.modifyPassword(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success("修改成功，请重新登录");
          that.close();
          that.$store.commit("CLEAR_LOGIN_DATA");
          sessionStorage.removeItem(constant.LOGIN_DATA);
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },

  }
}
</script>