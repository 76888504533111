<template>
  <div class="comptent title">
    我的首页
  </div>
</template>

<script>
export default {
  name: 'IndexSzzy',
};
</script>

<style scoped>
.title {
  font-size: 40px;
  color: red;
}
</style>