<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="字典类别" name="dictionaryCategoryCode">
        <a-select v-model:value="form.dictionaryCategoryCode" style="width: 150px" placeholder="请选择">
          <a-select-option value="1">章节</a-select-option>
          <a-select-option value="2">块</a-select-option>
          <a-select-option value="3">一级</a-select-option>
          <a-select-option value="4">二级</a-select-option>
          <a-select-option value="5">三级</a-select-option>
          <a-select-option value="6">疾病类型</a-select-option>
          <a-select-option value="7">核保类别</a-select-option>
          <a-select-option value="8">就诊方式</a-select-option>
          <a-select-option value="9">险种类别</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类别名称" name="categoryName">
        <a-input v-model:value="form.categoryName" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="toAdd">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEdit(record)">编辑</a>
            <a @click="deleteRecord(record)" style="margin-left: 5px; color:red">删除</a>
          </template>
        </template>

      </a-table>
    </div>

    <DictionaryModel ref="dictionaryModel" @ok="dictionaryModelOk"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import DictionaryModel from "./DictionaryModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '字典类别',
    dataIndex: 'dictionaryCategory',
    width: '2%',
    align: 'center',
  },
  {
    title: '类别编码',
    dataIndex: 'categoryCode',
    width: '8%',
    align: 'center',
  },
  {
    title: '类别名称',
    dataIndex: 'categoryName',
    width: '8%',
    align: 'center',
  },
  {
    title: '类别说明',
    dataIndex: 'categoryExplain',
    width: '8%',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '3%',
    align: 'center',
  },
];

export default {
  name: 'DictionaryList',
  mixins: [CommonMixin],
  components: {
    DictionaryModel
  },
  data() {
    return {
      form: {},
      rowKey: "categoryCode",
      columns,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      api.dictionaryList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    toEdit(record) {
      this.$refs.dictionaryModel.edit(record);
    },
    toAdd() {
      this.$refs.dictionaryModel.add(this.diseaseTypeList);
    },
    dictionaryModelOk() {
      this.loadData();
    },
    deleteRecord(record) {
      this.$confirm({
        title: '你确定要删除该条记录吗？',
        content: record.categoryName,
        onOk: () => {
          let that = this;
          api.deleteDictionary(record).then(res => {
            that.spinning = false;
            if (res.code != that.apiStatus.success) {
              that.$message.error(res.message);
              return;
            }
            that.$message.success(that.apiStatus.defaultSuccMsg);
            this.loadData();
          }).catch(() => {
            that.spinning = false;
            that.$message.error(that.apiStatus.defaultErrMsg);
          });
        }
      });
    }
  }
};
</script>
