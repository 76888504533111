<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :width="modelWidth">
    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">病历信息</h3>
      <a-descriptions bordered :column="{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}">
        <a-descriptions-item label="住院号">{{ medicalRecord.zyid }}</a-descriptions-item>
        <a-descriptions-item label="主诉">{{ medicalRecord.principleAction }}</a-descriptions-item>
        <a-descriptions-item label="现病史">{{ medicalRecord.nowDiseaseHistory }}</a-descriptions-item>
        <a-descriptions-item label="既往史">{{ medicalRecord.diseaseHistory }}</a-descriptions-item>
        <a-descriptions-item label="个人史">{{ medicalRecord.personalHistory }}</a-descriptions-item>
        <a-descriptions-item label="婚姻史">{{ medicalRecord.marriageHistory }}</a-descriptions-item>
        <a-descriptions-item label="月经史">{{ medicalRecord.menstrualHistory }}</a-descriptions-item>
        <a-descriptions-item label="家族史">{{ medicalRecord.familyHistory }}</a-descriptions-item>
        <a-descriptions-item label="体格检查">{{ medicalRecord.physicalExamination }}</a-descriptions-item>
        <a-descriptions-item label="辅助检查">{{ medicalRecord.auxiliaryExamination }}</a-descriptions-item>
        <a-descriptions-item label="出院情况">{{ medicalRecord.dischargeStatus }}</a-descriptions-item>
        <a-descriptions-item label="出院医嘱">{{ medicalRecord.dischargeOrder }}</a-descriptions-item>
        <a-descriptions-item label="诊治过程">{{ medicalRecord.treatment }}</a-descriptions-item>
      </a-descriptions>
    </div>

  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";


export default {
  name: 'MedicalRecordModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      form: {},
      modelWidth: "98%",
      medicalRecord: {},
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claim_query");
    },
    close() {
      this.visible = false;
    },
    show(informationTransactionId) {
      this.visible = true;
      api.queryMedicalRecord({"informationTransactionId": informationTransactionId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.medicalRecord = res.data;
      }).catch(() => {
        this.spinning = false;
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
