<template>
  <div class="comptent">
    <a-form layout="inline" ref="searchForm" :model="searchForm">
      <a-form-item label="医院编码" name="hospitalCode">
        <a-input v-model:value="searchForm.hospitalCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="医院名称" name="hospitalName">
        <a-input v-model:value="searchForm.hospitalName" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table :columns="columns" :data-source="data" bordered :pagination="pagination"
               :rowKey='record=>record.hospitalId' @change="handleTableChange" size="middle" :loading="loading"
               :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a-popconfirm title="确认添加?" @confirm="() => addRelation(record)">
              <a>添加</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>
    </div>
  </div>

</template>
<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'RelationData',
  mixins: [CommonMixin],
  data() {
    let pagination = {...this.$store.state.app.pagination};
    pagination.pageSize = 10;
    return {
      pagination: pagination,
      searchForm: {
        hospitalCode: "",
        hospitalName: "",
      },
      data: [],
      columns: this.buildColumns(),
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    buildColumns() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'hospitalId',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        },
        {
          title: '医院编码',
          dataIndex: 'hospitalCode',
          width: 150,
        },
        {
          title: '医院名称',
          dataIndex: 'hospitalName',
          width: 250,
        },
        {
          title: '机构等级',
          dataIndex: 'orgLevelName',
          width: 120,
        },
        {
          title: '机构类别',
          dataIndex: 'orgCategoryName',
          width: 120,
        },
        {
          title: '所在省',
          dataIndex: 'provinceName',
          width: 120,
        },
        {
          title: '所在市',
          dataIndex: 'cityName',
          width: 120,
        },
        {
          title: '所在区',
          dataIndex: 'districtName',
          width: 120,
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          width: 150,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
        },
      ];
      return columns;
    },
    loadData() {
      let that = this;
      that.loading = true;
      let param = {...this.searchForm};
      param.hospitalType = 1;
      param.pageNum = that.pagination.current;
      param.pageSize = that.pagination.pageSize;
      api.hospitalList(param).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.data = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    addRelation(record) {
      this.$emit('add', record.hospitalId);
    },
  },
};
</script>
