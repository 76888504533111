const user = {
    state: {
        logged: false,
        token: '',
        loginTimestamp: 0,
        operator: {},
        unitInfo: {},
        dictData: {},
        menuList: [],
        resourceList: [],
        areaTreeData: [],
        auxiliaryAreaTreeData: [],
    },

    mutations: {
        SET_LOGIN_DATA: (state, data) => {
            state.logged = true;
            state.token = data.token;
            state.operator = data.operator;
            state.unitInfo = data.unitInfo;
            state.dictData = data.dictData;
            state.menuList = data.menuList;
            state.resourceList = data.resourceList;
            state.areaTreeData = data.areaTreeData;
            state.auxiliaryAreaTreeData = data.auxiliaryAreaTreeData;
        },
        CLEAR_LOGIN_DATA: (state) => {
            state.logged = false;
            state.token = '';
            state.operator = {};
            state.unitInfo = {};
            state.dictData = {};
            state.menuList = [];
            state.resourceList = [];
            state.areaTreeData = [];
            state.auxiliaryAreaTreeData = [];
        },
        SET_LOGIN_TIMESTAMP: (state, data) => {
            state.loginTimestamp = data;
        },
        SET_TOKEN: (state, data) => {
            state.token = data;
        },
    },

    actions: {}
};

export default user;