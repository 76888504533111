<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="章节" name="chapter">
          <a-input v-model:value="form.chapter"/>
        </a-form-item>
        <a-form-item label="块" name="piece">
          <a-input v-model:value="form.piece"/>
        </a-form-item>
        <a-form-item label="一级" name="onelevel">
          <a-input v-model:value="form.onelevel"/>
        </a-form-item>
        <a-form-item label="二级" name="twolevel">
          <a-input v-model:value="form.twolevel"/>
        </a-form-item>
        <a-form-item label="三级" name="threelevel">
          <a-input v-model:value="form.threelevel"/>
        </a-form-item>
        <a-form-item label="ICD编码" name="icdCode">
          <a-input v-model:value="form.icdCode"/>
        </a-form-item>
        <a-form-item label="ICD名称" name="icdName">
          <a-input v-model:value="form.icdName"/>
        </a-form-item>
        <a-form-item label="疾病类别" name="diseaseProfileCode">
          <a-select v-model:value="form.diseaseProfileCode" placeholder="请选择">
            <a-select-option v-for="item in diseaseTypeList" :key="item.categoryCode">
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="出险原因" name="accidentReason">
          <a-select v-model:value="form.accidentReason" placeholder="请选择">
            <a-select-option value="1">疾病</a-select-option>
            <a-select-option value="2">意外</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="核保类别" name="accidentReason">
          <a-select v-model:value="form.underwritingCategory" placeholder="请选择">
            <a-select-option value="1">一类</a-select-option>
            <a-select-option value="2">二类</a-select-option>
            <a-select-option value="3">三类</a-select-option>
            <a-select-option value="4">四类</a-select-option>
            <a-select-option value="5">五类</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'IcdModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {},
      diseaseTypeList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_icd_conf");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add(diseaseTypeList) {
      this.visible = true;
      this.title = '新增ICD配置';
      this.diseaseTypeList = diseaseTypeList;
    },
    edit(record, diseaseTypeList) {
      this.visible = true;
      this.title = '编辑ICD配置';
      this.form = record;
      this.diseaseTypeList = diseaseTypeList;
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    save() {
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        api.saveIcd(that.formData()).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
