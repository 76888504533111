<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="名称" name="name">
          <a-input v-model:value="form.name"/>
        </a-form-item>
        <a-form-item label="icon(png,透明)" name="icon">
          <a-upload :action="action" :headers="uploadHeaders" :file-list="fileList" :show-upload-list="false"
                    :before-upload="beforeUpload" @change="handleFileChange">
            <span v-if="form.icon != ''" style="display: inline-block;background-color: #027B6C;cursor: pointer;">
              <img :src="form.icon" style="width: 100px;height: 100px;"/>
            </span>
            <div v-else class="avatar-uploader">
              点击上传
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="机构" name="unitId" v-if="hasPermission('claims_guide_apply_item_all')">
          <a-select v-model:value="form.unitId" :disabled="disableUnit">
            <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'ApplyItemModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      disableUnit: false,
      form: {
        id: null,
        name: '',
        icon: '',
        unitId: null
      },
      rules: {
        name: [
          {required: true, message: '请输入名称！', trigger: 'blur'},
        ],
        icon: [
          {required: true, message: '请选择Icon！', trigger: 'blur'},
        ],
      },
      // 上传文件
      action: window._CONFIG["apiBaseUrl"] + api.claimsGuideUploadIcon,
      fileList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claims_guide_apply_item");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add() {
      this.visible = true;
      this.title = '新增';
      this.disableUnit = false;
    },
    edit(record) {
      this.visible = true;
      this.title = '修改';
      this.disableUnit = true;
      this.form = {...record};
    },
    okCallback() {
      this.$emit('ok');
    },
    handleFileChange(info) {
      if (info.file.status === "done") {
        this.form.icon = info.file.response;
      } else if (info.file.status === "error") {
        let message = "上传失败！";
        if (info.file.response && info.file.response.message) {
          message = info.file.response.message;
        }
        this.$message.error(message);
      }
      this.fileList = info.fileList;
    },
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let parm = that.formData();
        that.spinning = true;
        api.saveClaimsGuideApplyItem(parm).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>

<style scoped>
.avatar-uploader {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  padding-top: 40px;
  cursor: pointer;
}
</style>