<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="ICD编码" name="icdCode">
        <a-input v-model:value="form.icdCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="ICD名称" name="icdName">
        <a-input v-model:value="form.icdName" class="query-condition"/>
      </a-form-item>
      <a-form-item label="核保类别" name="underwritingCategory">
        <a-select v-model:value="form.underwritingCategory" style="width: 150px" placeholder="请选择">
          <a-select-option value="1">一类</a-select-option>
          <a-select-option value="2">二类</a-select-option>
          <a-select-option value="3">三类</a-select-option>
          <a-select-option value="4">四类</a-select-option>
          <a-select-option value="5">五类</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="edit(record)">配置</a>
          </template>
        </template>

      </a-table>
    </div>

    <UnderwritingCategoryModal ref="underwritingCategoryModal" @ok="underwritingCategoryModalOk"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import UnderwritingCategoryModal from "./UnderwritingCategoryModal";

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '章节',
    dataIndex: 'chapter',
    width: '2%',
    align: 'center',
  },
  {
    title: '块',
    dataIndex: 'piece',
    width: '8%',
    align: 'center',
  },
  {
    title: '一级',
    dataIndex: 'onelevel',
    width: '8%',
    align: 'center',
  },
  {
    title: '二级',
    dataIndex: 'twolevel',
    width: '8%',
    align: 'center',
  },
  {
    title: '三级',
    dataIndex: 'threelevel',
    width: '8%',
    align: 'center',
  },
  {
    title: 'ICD编码',
    dataIndex: 'icdCode',
    width: '4%',
    align: 'center',
  },
  {
    title: 'ICD名称',
    dataIndex: 'icdName',
    width: '9%',
    align: 'center',
  },
  {
    title: '核保类别',
    dataIndex: 'underwritingCategory',
    width: '3%',
    align: 'center',
    customRender: ({text}) => {
      if (text == 1) {
        return "一类";
      }
      if (text == 2) {
        return "二类";
      }
      if (text == 3) {
        return "三类";
      }
      if (text == 4) {
        return "四类";
      }
      if (text == 5) {
        return "五类";
      }
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '3%',
    align: 'center',
  },
];

export default {
  name: 'UnderwritingCategoryList',
  mixins: [CommonMixin],
  components: {
    UnderwritingCategoryModal
  },
  data() {
    return {
      form: {},
      rowKey: "icdId",
      columns,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      api.underwritingCategoryList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    edit(record) {
      this.$refs.underwritingCategoryModal.edit(record);
    },
    underwritingCategoryModalOk() {
      this.loadData();
    },
  }
};
</script>
