export default {
    data() {
        return {
            provinceData: this.$store.state.user.areaTreeData,
            cityData: [],
            districtData: [],
        };
    },
    methods: {
        handleProvinceChange(value) {
            // 清空市
            this.form.cityId = null;
            if (value) {
                this.cityData = this.provinceData.find(function (item) {
                    return item.areaId === value;
                }).children;
            } else {
                this.cityData = [];
            }
            // 清空区
            this.form.districtId = null;
            this.districtData = [];
        },
        handleCityChange(value) {
            // 清空区
            this.form.districtId = null;
            if (value) {
                this.districtData = this.cityData.find(function (item) {
                    return item.areaId === value;
                }).children;
            } else {
                this.districtData = [];
            }
        },
    }
};