<template>
  <a-layout class="page-layout">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <img class="logo-img" src="@/assets/logo_sider.png" alt="logo">
        <h1 class="logo-title" v-if="!collapsed">知医科技</h1>
      </div>
      <a-menu theme="dark" mode="inline" :selectedKeys="selectedMenu" @select="handleMenuSelect">
        <a-menu-item key="index">
          <template #icon>
            <HomeOutlined/>
          </template>
          <span>首页</span>
        </a-menu-item>
        <template v-for="fmenu in $store.state.user.menuList" :key="fmenu.id">
          <a-menu-item :key="fmenu.id" v-if="fmenu.isLeaf == 1">
            <template #icon>
              <component :is="fmenu.icon"/>
            </template>
            <span>{{ fmenu.title }}</span>
          </a-menu-item>
          <a-sub-menu :key="fmenu.id" :title="fmenu.title" v-else>
            <template #icon>
              <component :is="fmenu.icon"/>
            </template>
            <template v-for="smenu in fmenu.children" :key="smenu.id">
              <a-menu-item :key="smenu.id" v-if="smenu.isLeaf == 1">{{ smenu.title }}</a-menu-item>
              <a-sub-menu :key="smenu.id" :title="smenu.title" v-else>
                <template v-for="tmenu in smenu.children" :key="tmenu.id">
                  <a-menu-item :key="tmenu.id" v-if="tmenu.isLeaf == 1">{{ tmenu.title }}</a-menu-item>
                </template>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="layout-header">
        <MenuUnfoldOutlined
            v-if="collapsed"
            class="trigger"
            @click="() => (collapsed = !collapsed)"/>
        <MenuFoldOutlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>
        <div style="padding: 0 20px;">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()" style="color: #002140;">
              {{ $store.state.user.operator.realName }}
              <DownOutlined/>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click="toEditOperator">基本资料</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="toModifyPassword">修改密码</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a href="javascript:;" @click="logout" style="color: #002140;padding-left: 20px;">退出</a>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-header class="layout-navigation">
          <a-menu mode="horizontal" :selectedKeys="selectedNavigation" @select="handleNavigationSelect">
            <a-menu-item v-for="menu in navigationData" :key="menu.id" class="layout-navigation-menu">
              <span>{{ menu.title }}</span>
              <div v-if="menu.id != 'index'" class="layout-navigation-close"
                   @click.stop="handleNavigationClose(menu.id)">X
              </div>
            </a-menu-item>
          </a-menu>
        </a-layout-header>
        <a-layout-content class="layout-content">
          <LayoutContent ref="layoutContent"/>
          <OperatorModel ref="operatorModel"/>
          <ModifyPassword ref="modifyPassword"/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import LayoutContent from "./LayoutContent";
import OperatorModel from "../orgconf/OperatorModel";
import ModifyPassword from "./ModifyPassword";
import constant from "@/store/constant";

export default {
  name: 'PageLayout',
  components: {LayoutContent, OperatorModel, ModifyPassword},
  data() {
    return {
      collapsed: false,
      selectedMenu: ['index'],
      selectedNavigation: ['index'],
      navigationData: [{id: 'index', title: '首页'}],
    };
  },
  methods: {
    handleMenuSelect(e) {
      this.selectedMenu = [e.key];
      let isSelected = false;
      this.navigationData.forEach(function (item) {
        if (item.id == e.key) {
          isSelected = true;
        }
      });
      if (isSelected) {
        this.openNavigationItem(e.key);
      } else {
        this.createNavigationItem(e.key);
      }
    },
    createNavigationItem(id) {
      let menu = null;
      this.$store.state.user.resourceList.forEach(function (item) {
        if (item.id == id) {
          menu = item;
        }
      });
      this.navigationData.push(menu);
      this.openNavigationItem(id);
    },
    openNavigationItem(id) {
      this.selectedNavigation = [id];
      this.$refs.layoutContent.changeMenu(id);
    },
    handleNavigationSelect(e) {
      this.openNavigationItem(e.key);
      this.selectedMenu = [e.key];
    },
    handleNavigationClose(id) {
      let data = [];
      this.navigationData.forEach(function (item) {
        if (item.id != id) {
          data.push(item);
        }
      });
      this.navigationData = data;
      // 选中首页
      this.selectedMenu = ['index'];
      this.selectedNavigation = ['index'];
      this.openNavigationItem('index');
    },
    clearNavigation() {
      this.selectedMenu = ['index'];
      this.selectedNavigation = ['index'];
      this.navigationData = [{id: 'index', title: '首页'}];
      this.$refs.layoutContent.clearNavigation();
    },
    logout() {
      let that = this;
      that.$confirm({
        title: '确定退出?',
        content: '',
        onOk() {
          that.$store.commit("CLEAR_LOGIN_DATA");
          sessionStorage.removeItem(constant.LOGIN_DATA);
        }
      });
    },
    toEditOperator() {
      this.$refs.operatorModel.edit(this.$store.state.user.operator, "app", null, "基础资料", true);
    },
    toModifyPassword() {
      this.$refs.modifyPassword.edit(this.$store.state.user.operator.operatorId);
    },
  }
};
</script>
<style scoped>
.page-layout {
  height: 100%;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 64px;
  line-height: 64px;
  background-color: #002140;
  padding-left: 24px;
  overflow: hidden;
}

.logo-img {
  display: inline-block;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: inline-block;
  color: #ffffff;
  font-size: 22px;
  margin: 0 0 0 8px;
  vertical-align: middle;
}

.layout-header {
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.layout-navigation {
  height: 50px;
  line-height: 50px;
  background: #fff;
  padding: 0;
  border: 1px solid #e8e8e8;
}

.layout-navigation-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.layout-navigation-menu:hover .layout-navigation-close {
  display: block;
}
</style>
