<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :width="modelWidth">
    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">档案信息</h3>
      <a-descriptions bordered>
        <a-descriptions-item label="姓名" :span="1">{{ archiveRecord.nameCode }}</a-descriptions-item>
        <a-descriptions-item label="证件号码" :span="1">{{ archiveRecord.userCode }}</a-descriptions-item>
        <a-descriptions-item label="性别" :span="1">{{ archiveRecord.genderStr }}</a-descriptions-item>
        <a-descriptions-item label="医疗类别" :span="1">{{ archiveRecord.medicalName }}</a-descriptions-item>
        <a-descriptions-item label="就诊科室" :span="1">{{ archiveRecord.treatDeptName }}</a-descriptions-item>
        <a-descriptions-item label="床位号" :span="1">{{ archiveRecord.bedNo }}</a-descriptions-item>
        <a-descriptions-item label="就诊医生" :span="1">{{ archiveRecord.treatDoctor }}</a-descriptions-item>
      </a-descriptions>
    </div>

    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">诊断列表</h3>
      <a-table :columns="archiveDiseaseColumns" :data-source="archiveDiseaseList" :rowKey="archivesDiseaseRowKey"
               :pagination="false"></a-table>
    </div>

    <div style="marginBottom: 25px">
      <h3 :style="{ marginBottom: '10px' }">信息提取</h3>
      <a-table :columns="informationTransactionColumns" :data-source="inforTransList"
               :rowKey="informationTransactionKey" :pagination="false">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="showDetail(record.informationTransactionId, record.transactionCode)"
               v-show="record.resultFlagStr == '成功'">详情</a>
          </template>
        </template>

      </a-table>
    </div>
    <FeeDetailModal ref="feeDetailModal"/>
    <LeaveHospitalModel ref="leaveHospitalModel"/>
    <MedicalRecordModel ref="medicalRecordModel"/>
  </a-modal>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import FeeDetailModal from "./FeeDetailModal";
import LeaveHospitalModel from "./LeaveHospitalModel";
import MedicalRecordModel from "./MedicalRecordModel";

const archiveDiseaseColumns = [
  {
    title: '序号',
    dataIndex: 'archivesDiseaseId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '诊断类别',
    dataIndex: 'diseaseType',
    width: '5%',
    customRender: ({text}) => {
      if (text == 'I') {
        return "入院诊断";
      }
      if (text == 'D') {
        return "出院诊断";
      }
      if (text == 'O') {
        return "门急诊诊断";
      }
    }
  },
  {
    title: '诊断代码',
    width: '5%',
    dataIndex: 'leaveIcdCode',
  },
  {
    title: '疾病名称',
    dataIndex: 'leaveIcdName',
    width: '5%',
  },
  {
    title: '是否主诊断',
    dataIndex: 'principalDiagnosis',
    width: '5%',
    customRender: ({text}) => {
      if (text == 'Y') {
        return "是";
      }
      if (text == 'N') {
        return "否";
      }
    }
  },
];
const informationTransactionColumns = [
  {
    title: '序号',
    dataIndex: 'archivesDiseaseId',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '提取类型',
    dataIndex: 'transactionName',
    width: '5%',
  },
  {
    title: '提取时间',
    width: '5%',
    dataIndex: 'queryTime',
  },
  {
    title: '返回时间',
    dataIndex: 'backTime',
    width: '5%',
  },
  {
    title: '提取结果',
    dataIndex: 'resultFlagStr',
    width: '5%',
    customCell: (record) => {
      if (record.resultFlagStr == '失败') {
        return {
          style: {
            'color': 'red'
          }
        }
      }
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '4%',
  },
];

export default {
  name: 'ArchiveModal',
  mixins: [CommonMixin],
  components: {
    FeeDetailModal, LeaveHospitalModel, MedicalRecordModel
  },
  data() {
    return {
      visible: false,
      spinning: false,
      form: {},
      modelWidth: "100%",
      archiveRecord: {},
      archiveDiseaseList: [],
      archiveDiseaseColumns,
      archivesDiseaseRowKey: "archivesDiseaseId",
      inforTransList: [],
      informationTransactionColumns,
      informationTransactionKey: "informationTransactionId"
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claim_query");
    },
    close() {
      this.visible = false;
    },
    show(archivesTransactionId) {
      this.visible = true;
      api.queryArchiveInfo({"archivesTransactionId": archivesTransactionId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.archiveRecord = res.data.archiveRecord;
        this.archiveDiseaseList = res.data.archiveDiseaseList;
        this.inforTransList = res.data.inforTransList;
      }).catch(() => {
        this.spinning = false;
        this.$message.error(this.apiStatus.defaultErrMsg);
      });
    },
    showDetail(informationTransactionId, transactionCode) {
      if (transactionCode == "B11002") {
        // 费用明细
        this.$refs.feeDetailModal.show(informationTransactionId);
      } else if (transactionCode == "B11003") {
        // 出院信息
        this.$refs.leaveHospitalModel.show(informationTransactionId);
      } else if (transactionCode == "B11004") {
        // 提取病例信息
        this.$refs.medicalRecordModel.show(informationTransactionId);
      }

    }
  }
}
</script>
