<template>
  <div class="comptent">
    <a-table
        v-if="dataSource.length>0"
        bordered
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :rowKey="rowKey"
        :loading="loading"
        :defaultExpandAllRows="true">


      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'icon'">
          <component :is="record.icon" v-if="showIcon(record.icon)"/>
        </template>
      </template>

    </a-table>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

const columns = [
  {
    title: '资源名称',
    dataIndex: 'title',
  },
  {
    title: '资源类型',
    dataIndex: 'resourceTypeName',
    align: 'center',
  },
  {
    title: 'ICON',
    dataIndex: 'icon',
    align: 'center',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    align: 'center',
    ellipsis: true
  },
  {
    title: '排序',
    dataIndex: 'seq',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    ellipsis: true
  },
];

export default {
  name: 'ResourceManager',
  mixins: [CommonMixin],
  data() {
    return {
      rowKey: 'resourceId',
      columns,
      dataSource: [],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      that.loading = true;
      api.resourceTree().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    showIcon(icon) {
      return icon ? true : false;
    },
  }
};
</script>
