<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称" name="name">
          <a-input v-model:value="form.name"/>
        </a-form-item>
        <a-form-item label="机构" name="unitId" v-if="hasPermission('claims_guide_applicant_identity_all')">
          <a-select v-model:value="form.unitId" :disabled="disableUnit">
            <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'ApplicantIdentityModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      disableUnit: false,
      form: {
        id: null,
        name: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入名称！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_claims_guide_applicant_identity");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add() {
      this.visible = true;
      this.title = '新增';
      this.disableUnit = false;
    },
    edit(record) {
      this.visible = true;
      this.title = '修改';
      this.disableUnit = true;
      this.form = {...record};
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let parm = that.formData();
        that.spinning = true;
        api.saveClaimsGuideApplicantIdentity(parm).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
