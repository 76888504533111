<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">刷新</a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="toEdit(record)">编辑</a>
          </template>
        </template>

      </a-table>
    </div>

    <TreatmentDiseaseModel ref="treatmentDiseaseModel" @ok="treatmentDiseaseModelOk"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import TreatmentDiseaseModel from "./TreatmentDiseaseModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    width: '2%',
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '字典类别',
    dataIndex: 'dictionaryCategory',
    width: '2%',
    align: 'center',
  },
  {
    title: '类别名称',
    dataIndex: 'treatmentMethodName',
    width: '8%',
    align: 'center',
  },
  {
    title: '类别说明',
    dataIndex: 'categoryExplain',
    width: '8%',
    align: 'center',
  },
  {
    title: '疾病类型',
    dataIndex: 'diseaseProfileNameStr',
    width: '8%',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '3%',
    align: 'center',
  },
];

export default {
  name: 'TreatmentDiseaseList',
  mixins: [CommonMixin],
  components: {
    TreatmentDiseaseModel
  },
  data() {
    return {
      form: {},
      rowKey: "treatmentMethodCode",
      columns,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    // searchReset() {
    //   this.$refs.form.resetFields();
    // },
    loadData() {
      let that = this;
      that.loading = true;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      api.treatmentDiseaseListList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    toEdit(record) {
      this.$refs.treatmentDiseaseModel.edit(record);
    },
    toAdd() {
      this.$refs.treatmentDiseaseModel.add(this.diseaseTypeList);
    },
    treatmentDiseaseModelOk() {
      this.loadData();
    },
    deleteRecord(record) {
      this.$confirm({
        title: '你确定要删除该条记录吗？',
        content: record.categoryName,
        onOk: () => {
          let that = this;
          api.deleteDictionary(record).then(res => {
            that.spinning = false;
            if (res.code != that.apiStatus.success) {
              that.$message.error(res.message);
              return;
            }
            that.$message.success(that.apiStatus.defaultSuccMsg);
            this.loadData();
          }).catch(() => {
            that.spinning = false;
            that.$message.error(that.apiStatus.defaultErrMsg);
          });
        }
      });
    }
  }
};
</script>
