<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="姓名" name="nameCode">
        <a-input v-model:value="form.nameCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="身份证" name="userCode">
        <a-input v-model:value="form.userCode" class="query-condition"/>
      </a-form-item>
      <a-form-item label="查询时间" name="queryTimeStr">
        <a-space direction="vertical">
          <a-range-picker v-model:value="form.queryTimeStr"/>
        </a-space>

      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="small"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="showInsuranceModal(record.archivesTransactionId)">保险信息</a>
            <a @click="showArchiveModal(record.archivesTransactionId)" style="margin-left: 10px;"
               v-show="record.resultFlag == 1">档案信息</a>
          </template>
        </template>

      </a-table>
    </div>

    <InsuranceModal ref="insuranceModal"/>
    <ArchiveModal ref="archiveModal"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import InsuranceModal from "./InsuranceModal";
import ArchiveModal from "./ArchiveModal";

export default {
  name: 'ClaimQueryList',
  mixins: [CommonMixin],
  components: {
    InsuranceModal, ArchiveModal
  },
  data() {
    let columns = [
      {
        title: '姓名',
        dataIndex: 'nameCode',
        width: '5%',
        align: 'center',
      },
      {
        title: '身份证',
        dataIndex: 'userCode',
        width: '6%',
        align: 'center',
      },
      {
        title: '查询时间',
        dataIndex: 'queryTime',
        width: '5%',
        align: 'center',
      },
      {
        title: '返回时间',
        dataIndex: 'backTime',
        width: '5%',
        align: 'center',
      },
      {
        title: '医院名称',
        dataIndex: 'hospitalName',
        width: '10%',
        align: 'center',
      },
      {
        title: '就诊方式',
        dataIndex: 'medicalName',
        width: '3%',
        align: 'center',
      },
      {
        title: '就诊时间',
        dataIndex: 'treatTimeStr',
        width: '5%',
        align: 'center',
      },
      {
        title: '提取档案',
        dataIndex: 'resultFlag',
        width: '3%',
        align: 'center',
        customCell: (record) => {
          if (record.resultFlag == '0') {
            return {
              style: {
                'color': 'red'
              }
            }
          }
        },
        customRender: ({text}) => {
          if (text == 0) {
            return "失败";
          }
          if (text == 1) {
            return "成功";
          }
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: '4%',
        align: 'center',
      },
    ];
    // 全部显示机构列
    if (this.hasPermission('claim_query_all')) {
      columns.unshift({
        title: '所属机构',
        dataIndex: 'unitName',
        width: '5%',
        align: 'center',
      });
    }
    columns.unshift({
      title: '序号',
      dataIndex: 'archivesTransactionId',
      align: 'center',
      width: '2%',
      customRender: ({index}) => {
        return index + 1;
      }
    });

    return {
      form: {},
      rowKey: "archivesTransactionId",
      columns,
      dataSource: [],
      loading: false,
      diseaseTypeList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      if (that.form.queryTimeStr) {
        that.form.queryStartTime = that.form.queryTimeStr[0];
        that.form.queryEndTime = that.form.queryTimeStr[1];
      }
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      api.claimsList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    showInsuranceModal(archivesTransactionId) {
      api.queryInsuranceList({"archivesTransactionId": archivesTransactionId}).then(res => {
        if (res.code != this.apiStatus.success) {
          this.$message.error(res.message);
          return;
        }
        this.insuranceList = res.data;
        if (res.data && res.data.length > 0) {
          this.$refs.insuranceModal.show(res.data);
        } else {
          this.$message.success("无数据");
        }
      }).catch(() => {
        this.spinning = false;
        this.$message.error(this.apiStatus.defaultErrMsg);
      });

    },
    showArchiveModal(archivesTransactionId) {
      this.$refs.archiveModal.show(archivesTransactionId);
    },
  }
};
</script>
