<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :rules="rules">
        <a-form-item label="就诊方式" name="treatmentMethodName">
          <a-input v-model:value="form.treatmentMethodName" :disabled="true"/>
        </a-form-item>
        <a-form-item label="疾病类型" name="diseaseProfileCodeList">
          <a-select mode="multiple" v-model:value="form.diseaseProfileCodeList" :allowClear="true" placeholder="请选择">
            <a-select-option v-for="item in diseaseOptionList" :key="item.categoryCode">
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'TreatmentDiseaseModel',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {},
      editFlag: false,
      rules: {
        diseaseProfileCodeList: [
          {required: true, message: '请选择疾病类型！', trigger: 'blur'},
        ]
      },
      diseaseOptionList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_treatment_method");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$refs.form.clearValidate();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    edit(record) {
      let that = this;
      that.visible = true;
      that.title = '就诊方式维护';
      that.form = record;
      if (record.diseaseProfileCodeStr) {
        that.form.diseaseProfileCodeList = record.diseaseProfileCodeStr.split(",");
      } else {
        that.form.diseaseProfileCodeList = [];
      }
      api.queryBaseDictionary({"dictionaryCategoryCode": 6}).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.diseaseOptionList = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    save() {
      let that = this;
      that.$refs.form.validate().then(() => {
        that.spinning = true;
        let param = that.formData();
        api.saveTreatmentDisease(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
