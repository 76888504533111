<template>
  <div class="comptent">
    <a-table
        bordered
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        rowKey="unitId"
        :loading="loading"
        :scroll="{ x: 'max-content'}">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a @click="material(record)">资料配置</a>
        </template>
      </template>

    </a-table>

    <MaterialConfigModal ref="materialConfigModal"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import MaterialConfigModal from "./MaterialConfigModal";

const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '机构编码',
    dataIndex: 'unitCode',
    width: 150,
    align: 'center',
  },
  {
    title: '机构名称',
    dataIndex: 'unitName',
    width: 200,
    align: 'center',
  },
  {
    title: '机构简称',
    dataIndex: 'unitAlias',
    width: 150,
    align: 'center',
  },
  {
    title: '行政归属地',
    dataIndex: 'address',
    width: 150,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 80,
    align: 'center',
  },
];

export default {
  name: 'MaterialConfig',
  mixins: [CommonMixin],
  components: {MaterialConfigModal},
  data() {
    return {
      columns,
      dataSource: [],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      that.loading = true;
      api.claimsGuideUnitData().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    material(record) {
      this.$refs.materialConfigModal.show(record.unitId, record.unitName);
    },
  }
};
</script>
