<template>
  <div>
    <a-tree v-if="treeData.length>0" :tree-data="treeData" v-model:checkedKeys="checkedLeafKeys" :checkable="true"
            @check="onCheck"/>
    <a-button type="primary" @click="handleSubmit" :loading="loading" style="margin-top: 20px;">
      确定
    </a-button>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'AuxiliaryAreaTree',
  mixins: [CommonMixin],
  data() {
    return {
      loading: false,
      unitId: null,
      dataSourceId: null,
      treeData: [],
      checkedLeafKeys: [],
      checkedAllKeys: []
    }
  },
  methods: {
    clear() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    render(unitId, dataSourceId, tree) {
      this.unitId = unitId;
      this.dataSourceId = dataSourceId;
      this.treeData = tree.treeData;
      this.checkedLeafKeys = tree.checkedLeafKeys;
      this.checkedAllKeys = tree.checkedAllKeys;
    },
    onCheck(checkedLeafKeys, e) {
      this.checkedAllKeys = checkedLeafKeys.concat(e.halfCheckedKeys);
    },
    handleSubmit() {
      let that = this;
      let parm = {
        unitId: that.unitId,
        dataSourceId: that.dataSourceId,
        areaIds: that.checkedAllKeys
      };
      that.loading = true;
      api.saveAuxiliaryArea(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    }
  }
}
</script>
