<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="机构" name="unitId" v-if="hasPermission('auxiliary_before_precise_all')">
        <a-select v-model:value="form.unitId" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in dictData.unitData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="姓名" name="nameCode">
        <a-input v-model:value="form.nameCode"/>
      </a-form-item>
      <a-form-item label="身份证" name="userCode">
        <a-input v-model:value="form.userCode"/>
      </a-form-item>
      <a-form-item label="排查标记" name="positiveFlagCode">
        <a-select v-model:value="form.positiveFlagCode" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in positiveFlagData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="排查结果" name="resultFlagCode">
        <a-select v-model:value="form.resultFlagCode" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in resultFlagData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="上传凭证" name="licenseFlag">
        <a-select v-model:value="form.licenseFlag" :allowClear="true" class="query-condition">
          <a-select-option v-for="item in licenseFlagData" :key="item.code" :value="item.code">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="核保时间" name="timeRange">
        <a-range-picker
            v-model:value="form.timeRange"
            format="YYYY-MM-DD"
            style="width:280px"
        >
          <template #suffixIcon>
            <CalendarOutlined/>
          </template>
        </a-range-picker>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button type="primary" @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="handleExport" style="margin-left: 10px"
                  v-if="hasPermission('auxiliary_before_precise_export')">导出
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          :rowKey="rowKey"
          :components="tableDraggableHeader"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a @click="handleDetail(record)" v-if="record.resultFlagCode === 1">详情</a>
            <a @click="handleUpload(record)" style="margin-left: 10px;">
              <span v-if="record.licenseFlag === 1">重传凭证</span>
              <span v-else>上传凭证</span>
            </a>
          </template>
        </template>

      </a-table>
    </div>

    <RecordList ref="recordList"/>
    <ExportModal ref="exportModal"/>
    <UploadModal ref="uploadModal" @ok="uploadModalOk"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonMixin from "@/mixin/CommonMixin";
import RecordList from './RecordList';
import ExportModal from './ExportModal';
import UploadModal from './UploadModal';

export default {
  name: 'PreciseQuery',
  mixins: [CommonMixin],
  components: {
    RecordList, ExportModal, UploadModal
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      form: {
        unitId: null,
        nameCode: '',
        userCode: '',
        positiveFlagCode: null,
        resultFlagCode: null,
        licenseFlag: null,
        timeRange: null,
      },
      rowKey: 'resultId',
      columns: this.createColumn(),
      dataSource: [],
      loading: false,
      positiveFlagData: [
        {code: "0", text: "阳性"},
        {code: "1", text: "阴性"},
        {code: "2", text: "待审核"}
      ],
      licenseFlagData: [{code: 1, text: "是"}, {code: 0, text: "否"}],
    }
  },
  methods: {
    createColumn() {
      let columns = [
        {
          title: '序号',
          dataIndex: 'rowIndex',
          align: 'center',
          width: 70,
          customRender: ({index}) => {
            return index + 1;
          }
        }
      ];

      if (this.hasPermission('auxiliary_before_precise_all')) {
        columns.push({
          title: '所属机构',
          dataIndex: 'unitName',
          width: 150,
          align: 'center',
        });
      }

      columns = columns.concat([
        {
          title: '姓名',
          dataIndex: 'nameCode',
          width: 80,
          align: 'center',
        },
        {
          title: '身份证',
          dataIndex: 'userCode',
          width: 170,
          align: 'center',
        },
        {
          title: '区域',
          dataIndex: 'cityName',
          width: 120,
          align: 'center',
        },
        {
          title: '排查标记',
          dataIndex: 'positiveFlagName',
          width: 120,
          align: 'center',
        },
        {
          title: '就医记录',
          dataIndex: 'recordCount',
          width: 90,
          align: 'center',
        },
        {
          title: '核保时间',
          dataIndex: 'checkTime',
          width: 170,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          align: 'center',
        },
      ]);
      return columns;
    },
    formData() {
      let data = {...this.form};
      if (data.timeRange && data.timeRange.length > 0 && data.timeRange[0] && data.timeRange[1]) {
        data.queryDate = data.timeRange[0].format("YYYY-MM-DD") + "~" + data.timeRange[1].format("YYYY-MM-DD");
      }
      return data;
    },
    loadData() {
      let that = this;
      that.form.pageNum = that.pagination.current;
      that.form.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.getBeforePreciseResultPageList(that.formData()).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleDetail(record) {
      this.$refs.recordList.show(record.resultId);
    },
    handleUpload(record) {
      this.$refs.uploadModal.show(record.resultId);
    },
    uploadModalOk() {
      this.loadData();
    },
    handleExport() {
      this.$refs.exportModal.show();
    },
  }
}
</script>
