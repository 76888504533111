<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="false"
           :maskClosable="false" :footer="null" :afterClose="afterClose">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="姓名" name="nameCode">
          <a-input v-model:value="form.nameCode"/>
        </a-form-item>
        <a-form-item label="身份证" name="userCode">
          <a-input v-model:value="form.userCode"/>
        </a-form-item>
        <a-form-item label="区域" name="areaData">
          <a-cascader v-model:value="form.areaData" :options="auxiliaryAreaTreeData" placeholder=""
                      expand-trigger="hover"/>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api';
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: 'RiskModal',
  mixins: [CommonMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      title: '添加',
      auxiliaryAreaTreeData: this.$store.state.user.auxiliaryAreaTreeData,
      form: {
        nameCode: '',
        userCode: '',
        areaData: [],
      },
      rules: {
        nameCode: [
          {required: true, message: '请输入姓名！', trigger: 'blur'},
        ],
        userCode: [
          {required: true, message: '请输入身份证！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_auxiliary_after_risk");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    add() {
      this.visible = true;
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      let data = {...this.form};
      if (data.areaData.length > 0) {
        data.cityId = data.areaData[1];
      }
      return data;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let parm = that.formData();
        that.spinning = true;
        api.auxiliaryAfterRiskAdd(parm).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
