<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="1400">
    <div style="text-align: center;font-size: 20px;padding-bottom: 10px;color: red;">已关联医院</div>
    <a-table bordered rowKey="hospitalId" :columns="columns" :data-source="dataSource"
             :pagination="false" :loading="loading" :scroll="{ x: 'max-content'}">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-popconfirm title="确认删除?" @confirm="() => deleteRelation(record)">
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a-popconfirm title="确认同步信息?" @confirm="() => syncHospitalInfo(record)">
            <a>同步信息</a>
          </a-popconfirm>
        </template>
      </template>

    </a-table>

    <RelationData @add="addRelation"/>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import RelationData from './RelationData';
import api from "@/api";

const columns = [
  {
    title: '序号',
    dataIndex: 'hospitalId',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '医院编码',
    dataIndex: 'hospitalCode',
    width: 150,
  },
  {
    title: '医院名称',
    dataIndex: 'hospitalName',
    width: 250,
  },
  {
    title: '机构等级',
    dataIndex: 'orgLevelName',
    width: 120,
  },
  {
    title: '机构类别',
    dataIndex: 'orgCategoryName',
    width: 120,
  },
  {
    title: '所在省',
    dataIndex: 'provinceName',
    width: 120,
  },
  {
    title: '所在市',
    dataIndex: 'cityName',
    width: 120,
  },
  {
    title: '所在区',
    dataIndex: 'districtName',
    width: 120,
  },
  {
    title: '详细地址',
    dataIndex: 'address',
    width: 150,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 125,
  },
];

export default {
  name: 'RelationModel',
  mixins: [CommonMixin],
  components: {
    RelationData
  },
  data() {
    return {
      hospitalId: null,
      visible: false,
      title: '',
      columns,
      dataSource: [],
      loading: false,
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_medical_platform_hospital");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.$emit('ok');
    },
    loadData() {
      let that = this;
      that.loading = true;
      api.getRelationedHospital(that.hospitalId).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    show(hospitalId, hospitalName) {
      this.hospitalId = hospitalId;
      this.visible = true;
      this.title = hospitalName;
      this.loadData();
    },
    deleteRelation(record) {
      let that = this;
      let parm = {sourceHospitalId: that.hospitalId, targetHospitalId: record.hospitalId};
      api.deleteHospitalRelationship(parm).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.loadData();
        that.$message.success(that.apiStatus.defaultSuccMsg);
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    syncHospitalInfo(record) {
      let that = this;
      let parm = {sourceHospitalId: that.hospitalId, targetHospitalId: record.hospitalId};
      api.syncHospitalInfo(parm).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addRelation(targetHospitalId) {
      let that = this;
      let parm = {sourceHospitalId: that.hospitalId, targetHospitalId: targetHospitalId};
      api.addHospitalRelationship(parm).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.loadData();
        that.$message.success(that.apiStatus.defaultSuccMsg);
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    }
  }
}
</script>